import DefaultApiService from '@/services/defaultApi.service'

const defaultApiService = new DefaultApiService('calc/')
const ENDPOINT = 'self'

class CalcService {
  list (params) {
    return defaultApiService.list(ENDPOINT, params)
  }

  listCalcs (params) {
    return defaultApiService.list('listCalcs', params)
  }

  adminListCalcs (params) {
    return defaultApiService.list('adminListCalcs', params)
  }

  definitions (params) {
    return defaultApiService.list('definitions', params)
  }

  get (id, params) {
    return defaultApiService.get(ENDPOINT, id, params)
  }

  create (data, params) {
    return defaultApiService.create(ENDPOINT, data, params)
  }

  update (data, params) {
    return defaultApiService.update(ENDPOINT, data, params)
  }

  updateAcls (data, params) {
    return defaultApiService.update('acls', data, params)
  }

  delete (id, params) {
    return defaultApiService.delete(ENDPOINT, id, params)
  }

  count (params) {
    return defaultApiService.count('systemsCount', params)
  }

  findOu (params) {
    return defaultApiService.list('ou', params)
  }

  findUser (params) {
    return defaultApiService.list('user', params)
  }

  loadSettings (params) {
    return defaultApiService.get('settings', null, params)
  }

  saveSettings (data, params) {
    return defaultApiService.update('settings', data, params)
  }
}

export default new CalcService()
