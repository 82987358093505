<template>
  <div class="home mx-3">
    <h1>Datenschutz</h1>

    Diese Datenschutzerklärung gilt für die Webseite:<br/>
    <a href="https://www.angebotskalkulation.zuv.fau.de">https://www.angebotskalkulation.zuv.fau.de</a>

    <h2>Allgemeines</h2>
    <p>Datenschutz ist unser Anliegen und unsere gesetzliche Verpflichtung. Um die Sicherheit Ihrer Daten angemessen
    bei der Übertragung zu schützen, verwenden wir – orientiert am aktuellen Stand der Technik – entsprechende
    Verschlüsselungsverfahren (z. B. SSL/TLS) sowie gesicherte technische Systeme.

    Für die Webseiten wird als aktive Komponente Javascript verwendet. Sollten Sie diese Funktion in Ihrem Browser
    deaktiviert haben, ist die Bedienung der Seite nicht sinnvoll möglich.
    </p>
    <h2>Verantwortlicher</h2>
    <p>
    Friedrich-Alexander-Universität Erlangen-Nürnberg<br/>

    Der Präsident der Friedrich-Alexander-Universität Erlangen-Nürnberg<br/>
    Schlossplatz 4<br/>
    91054 Erlangen<br/>
    Postadresse: Postfach 3520<br/>
    91023 Erlangen<br/><br/>

    Telefon: +49 9131 85-0<br/>
    Fax: +49 9131 85-22131<br/>
    E-Mail: poststelle@fau.de<br/>
    </p>
    <h2>Inhaltlich verantwortlich</h2>
    <p>
    Zentrale Universitätsverwaltung<br/>
    Referat H3 - Drittmittel
    und Rechtsangelegenheiten der Forschung<br/>
    Axel Klon<br/>
    Wöhrmühle 2<br/>
    91056 Erlangen<br />
    E-Mail: <a href="mailto:axel.klon@fau.de">axel.klon@fau.de</a>
    </p>

    <h2>Datenschutzbeauftragter FAU</h2>
    <p>
      Klaus Hoogestraat<br/>
      c/o ITM Gesellschaft für IT-Management mbH<br/>
      Bürgerstraße 81<br/>
      01127 Dresden <br/>
      Telefon: +49 9131 85-25860<br/>
      E-Mail: <a href="mailto:datenschutzbeauftragter@fau.de">datenschutzbeauftragter@fau.de</a>
    </p>

    <h2>Allgemeines zur Datenverarbeitung</h2>
    <h3>Zwecke und Rechtsgrundlagen der Verarbeitung</h3>
    <p>
      Wir bieten gemäß Art. 2 Abs. 6 BayHSchG, Art. 4 Abs. 1 S. 1 und 2 BayEGovG auf unseren Webseiten unsere Dienste und Verwaltungsleistungen sowie Informationen für die Öffentlichkeit über unsere Tätigkeit.

      Unser Bestreben ist es, zielgruppengerecht zu informieren und sich mit Ihnen auszutauschen, Art. 2 Abs. 6 BayHSchG. Wir ermöglichen Ihnen eine schnelle elektronische Kontaktaufnahme und unmittelbare Kommuniktaion über die Medien Ihrer Wahl, § 5 Abs. 1 Nr. 2 TMG. Inhalte oder Anfragen, die Rechte Dritter verletzen, den Tatbestand einer Straftat oder Ordnungswidrigkeit erfüllen oder gesetzlichen oder vertraglichen Verhaltenspflicht nicht entsprechen, legen wir durch Übermittlung an die zuständige Behörde offen und blockieren oder löschen diese.

      Cookies, Protokolldateien und Statistiken verwenden wir anonymisiert zur Erstellung von Geschäftsstatistiken, zur Durchführung von Organisationsuntersuchungen, zur Prüfung oder Wartung unseres Webdienstes und zur Gewährleistung der Netz- und Informationssicherheit gemäß Art. 6 Abs. 1 BayDSG.
    </p>
    <h4>Authentifizierung über den Identity Provider (IdP)</h4>
    <p>
      Beim Zugriff auf einen durch Shibboleth geschützten Webdienst erfolgt ein Redirect zur Login-Seite des IdP. Die dort verwendeten Zugangsdaten dienen der Authentifizierung am IdP und werden nicht an den Webdienst weitergegeben. Dieser erhält eine Bestätigung der Authentifizierung und eine vorher festgelegte Liste von Attributen.
    </p>
    <h3>Datenkategorien</h3>
    <h4>Protokolldateien</h4>
    <p>
      Wenn Sie diese oder andere Internetseiten aufrufen, übermitteln Sie über Ihren Internetbrowser Daten an unsere Webserver. Die folgenden Daten werden während einer laufenden Verbindung zur Kommunikation zwischen Ihrem Internetbrowser und unserem Webserver aufgezeichnet:
      <ul>
        <li>Datum und Uhrzeit der Anforderung</li>
        <li>Name der angeforderten Datei</li>
        <li>Seite, von der aus die Datei angefordert wurde</li>
        <li>Zugriffsstatus (beispielsweise Datei übertragen, Datei nicht gefunden)</li>
        <li>verwendeter Webbrowser und Bildschirmauflösung sowie das verwendete Betriebssystem</li>
        <li>vollständige IP-Adresse des anfordernden Rechners</li>
        <li>übertragene Datenmenge</li>
      </ul>
    </p>
    <h4>Cookies</h4>
    <p>
      Das Sitzungscookie JSESSIONID wird benötigt, um Anfragen einer Sitzung zuordnen zu können.
    </p>
    <h4>Kommunikation</h4>
    <p>Wenn Sie uns ein Anliegen ode eine Meinung per E-Mail, Post, Telefon oder Fax mitteilen, werden die gemachten Angaben zum Zwecke der Bearbeitung des Anliegens sowie für mögliche Anschlussfragen und zum Meinungsaustausch verarbeitet. Dafür setzen wir immer den gleichen Kommunikationsweg ein, sofern Sie keinen Wechsel wünschen. </p>

    <h3>Übermittlung von personenbezogenen Daten an ein Drittland oder internationale Organisationen</h3>
    <p>Eine Weitergabe von persönlichen Informationen an Drittländer oder internationale Organisationen findet nicht statt. </p>
    <h3>Dauer der Speicherung der personenbezogenen Daten</h3>
      <h4>Protokolldateien</h4>
      <p> Werden als personenbezogene Daten im Regelfall höchstens sieben Tage aufbewahrt. </p>
      <h4>Cookies</h4>
      <p>Das Sitzungscookie JSESSIONID wird beim Beenden der Browsersitzung gelöscht. </p>
      <h4>Kommunikation</h4>
    <p> Post, E-Mails und Nachrichten werden sechs Jahre nach Jahresende, in dem der jeweilige Vorgang liegt, gelöscht. </p>

    <h2>Betroffenenrechte</h2>
    <p>
      Nach der Datenschutz-Grundverordnung stehen Ihnen folgende Rechte zu:<br/>
      Werden Ihre personenbezogenen Daten verarbeitet, so haben Sie das Recht, Auskunft über die zu Ihrer Person gespeicherten Daten zu erhalten (Art. 15 DSGVO).<br/>

      Sollten unrichtige personenbezogene Daten verarbeitet werden, steht Ihnen ein Recht auf Berichtigung zu (Art. 16 DSGVO).<br/>

      Liegen die gesetzlichen Voraussetzungen vor, so können Sie die Löschung oder Einschränkung der Verarbeitung verlangen sowie Widerspruch gegen die Verarbeitung einlegen (Art. 17, 18 und 21 DSGVO).<br/>

      Wenn Sie in die Datenverarbeitung eingewilligt haben oder ein Vertrag zur Datenverarbeitung besteht und die Datenverarbeitung mithilfe automatisierter Verfahren durchgeführt wird, steht Ihnen gegebenenfalls ein Recht auf Datenübertragbarkeit zu (Art. 20 DSGVO).<br/>

      Sollten Sie von Ihrem oben geannten Recht Gebrauch machen, prüft die öffentliche Stelle, ob die gesetzlichen Voraussetzungen hierfür erfüllt sind.<br/>

      Weiterhin besteht ein Beschwerderecht beim Bayerischen Landesbeauftragten für Datenschutz.
    </p>
    <h2>Sonstiges zu unserer Datenschutzerklärung</h2>
    <p> Wir behalten uns vor, diese Datenschutzerklärung gelegentlich anzupassen, damit diese stets den aktuellen rechltlichen Anforderungen entspricht oder, um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z. B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.</p>
    <h2>Weitergabe von Daten </h2>
    <p>
      Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet nicht statt.
  <br/>
      Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:
      <ul>
    <li>Sie Ihre nach Art. 6 Abs.1a DSGVO ausdrückliche Einwilligung dazu erteilt haben,</li>
    <li>für den Fall, dass für die Weitergabe nach Art. 6 Abs.1c DSGVO eine gesetzliche Verpflichtung besteht, sowie</li>
    <li>dies gesetzlich zulässig und nach Art. 6 Abs.1b DSGVO für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist.</li>
  </ul>
    </p>
  </div>
</template>

<script>
export default {
  name: 'Privacy',
  mounted: function () {
    this.scrollToTop()
  },
  methods: {
    scrollToTop () {
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style lang="scss">
.home {
  min-height: calc(100vh - 161px);
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

</style>
