
export const dateMixin = {
  methods: {
    formatDateTime (value, missingText) {
      if (!value) {
        if (missingText !== undefined) {
          return missingText
        }
        return this.$t('missing.text')
      }
      const d = new Date(value)
      if (d instanceof Date) {
        return this.$d(d, 'long')
      }
      return ''
    },
    formatDateDay (value, missingText) {
      if (!value) {
        if (missingText !== undefined) {
          return missingText
        }
        return this.$t('missing.text')
      }
      const d = new Date(value)
      if (d instanceof Date) {
        return this.$d(d, 'short')
      }
      return ''
    }
  }
}
