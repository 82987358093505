import Vue from 'vue'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
// import '@/assets/css/custom.scss'
// import '@/assets/css/variables.scss'
// import '@/assets/css/ak3.css'
// import '@/assets/css/ak3.scss'
import '@/assets/lighdm.scss'
import '@/assets/nighdm.scss'
import '@/assets/css/ak3.css'

import 'vue-multiselect/dist/vue-multiselect.min.css'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
