import _ from 'lodash'
import store from '@/store'

class EmpcostService {
  defaultStaff () {
    return _.find(store.state.calc.staffSalary.values, s =>
      s.default === true
    ).label
  }

  empCost (employee) {
    // see: https://stackoverflow.com/questions/11832914/round-to-at-most-2-decimal-places-only-if-necessary
    if (employee.employmentType === 'staff') {
      return (store.state.calc.staffSalary.values[employee.payGrade].amount / 12) * employee.months * (employee.percentage / 100) * store.state.calc.staffPrf + Number.EPSILON
    } else if (employee.employmentType === 'hk') {
      return store.state.calc.hkSalary.values[employee.type][employee.hpw] * employee.months * store.state.calc.hkPrf + Number.EPSILON
    }
  }

  yearMap (startDate, endDate) {
    const startYear = startDate.getFullYear()
    const endYear = endDate.getFullYear()
    const res = new Map()
    let y
    for (y = startYear; y <= endYear; y++) {
      const nyd = new Date(y, 0, 1)
      const nye = new Date(y, 11, 31)
      // found this somewhere in the internet, works
      const daysBetween = Math.round(((endDate < nye ? endDate : nye).getTime() - (startDate > nyd ? startDate : nyd).getTime()) / 8.64e7) + 1
      res.set(y, daysBetween)
    }
    return res
  }

  payRiseFactor (baseYear, startDate, endDate, annualIncrease, annualIncreaseSpecificValues = new Map()) {
    console.log('=======================================')
    console.log('Berechne Inflationsfaktor: Basisjahr der Werte ist ' + baseYear + ', Start: ' + startDate.toLocaleDateString('de-DE') + ' Ende:  ' + endDate.toLocaleDateString('de-DE'))
    let multiplier = 1
    console.log('Inflationsfaktor: ' + multiplier)
    if (baseYear < startDate.getFullYear()) {
      console.log('Erhöhungen des Faktors in den Jahren vor Projektbeginn:')
      for (let y = baseYear + 1; y <= startDate.getFullYear(); y++) {
        const increaseForYear = annualIncreaseSpecificValues[y] || annualIncrease
        const percent = Math.round((increaseForYear - 1 + Number.EPSILON) * 10000) / 100
        console.log(y + ': Inflation ' + percent + '% -> Inflationsfaktor: ' + multiplier + '*' + increaseForYear + ' = ' + (multiplier * increaseForYear))
        multiplier = multiplier * increaseForYear
      }
    }
    console.log('Berechnung des Faktors während Projektlaufzeit (tagesgenauer Anteil der Werte der einzelnen Jahre):')
    const yearMap = this.yearMap(startDate, endDate)
    const daysTotal = _.sum(Array.from(yearMap.values()))
    const yearsList = Array.from(yearMap.keys())
    let calculatedPayRiseFactor = 0
    for (const [y, daysInYear] of yearMap) {
      const fraction = daysInYear / daysTotal
      console.log(y + ': ' + daysInYear + ' von ' + daysTotal + ' Gesamtprojekttagen (' + fraction * 100 + ' %) werden mit Inflationsfaktor ' + multiplier + ' angesetzt.')
      const perYear = multiplier * fraction
      console.log('      ' + multiplier + ' * ' + fraction + ' = ' + perYear)
      calculatedPayRiseFactor += perYear
      if (yearsList.includes(y + 1)) {
        const increaseForYear = annualIncreaseSpecificValues[y + 1] || annualIncrease
        const percent = Math.round((increaseForYear - 1 + Number.EPSILON) * 10000) / 100
        console.log('      Inflation ' + (y + 1) + ': ' + percent + '% -> Inflationsfaktor: ' + (multiplier * increaseForYear) + ' (' + multiplier + ' * ' + increaseForYear + ')')
        multiplier = multiplier * increaseForYear
      }
    }
    const res = Math.max(calculatedPayRiseFactor, 1)
    console.log('Preiserhöhungsfaktor: ' + res)
    return res
  }
}

// export default new EmpcostService(new Map())
export default new EmpcostService()
