<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-1 col-xl-1 ak-navbar">
        <TheBaReSuDeButtons v-if="isInternalAudit()" class="mt-3"
                            @adminlist="adminlist"
                            :show-adminlist="true"
                            :show-back="false"
                            :show-submit="false"
                            :show-pdf="false"
                            :show-delete="false"
        />
      </div>
      <div class="col-12 col-md-10 col-xl-10">
        <h1>{{ $t("headingCalclist") }}</h1>
        <div class="m-2 text-left row">
          <div class="col-12 mb-1">
            <div class="row">
              <div class="col-8">
                <b-button @click="newCalculation" variant="success" size="sm" class="mr-2">
                  <b-icon-plus/>
                  {{ $t('add.label', [$t('calculation')]) }}
                </b-button>
              </div>
            </div>
          </div>
          <div class="col-12">
    <b-container fluid>
      <!-- User Interface controls -->
      <b-row>
        <b-col lg="6" class="my-1">
          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            :placeholder="$t('typeToSearch.label')"
          ></b-form-input>
        </b-col>
        <b-col lg="6" class="my-1">
          <b-form-group
            v-model="sortDirection"
            :label="$t('filter.label')"
            :description="$t('filter.hint')"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
            v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
              v-model="filterOn"
              :aria-describedby="ariaDescribedby"
              class="mt-1">
              <b-form-checkbox value="title">{{ $t('title.label') }}</b-form-checkbox>
              <b-form-checkbox value="calculationNumber">{{ $t('calculationNumber.label') }}</b-form-checkbox>
              <b-form-checkbox value="clientCountryEU">EU</b-form-checkbox>
              <b-form-checkbox value="owner">{{ $t('owner.label') }}</b-form-checkbox>
              <b-form-checkbox value="projectLeader">{{ $t('projectLeader.label') }}</b-form-checkbox>
              <b-form-checkbox value="client">{{$t('client.label')}}</b-form-checkbox>
              <b-form-checkbox value="startDate">{{$t('startDate.label')}}</b-form-checkbox>
              <b-form-checkbox value="endDate">{{$t('endDate.label')}}</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
    <b-table class="mb-2 mt-2" small striped hover show-empty :empty-text="$t('tableEmpty.label')" :items="calcs"
             :fields="tableFields()" :filter="filter" :filter-included-fields="filterOn"
             :empty-filtered-text="$t('filter.tableEmpty.label')"
             sort-icon-left>
      <template #cell(show)="data">
        <b-button size="sm" @click="show(data.item)" title="Anzeigen" class="mr-1"><b-icon-search/></b-button>
      </template>
      <template #cell(actions)="data">
        <b-button size="sm" variant="info" @click="clone(data.item)" title="Kopie erstellen" class="mr-1" :disabled="!isOwner(data.item.acls)"><b-icon-files/></b-button>
        <b-button size="sm" variant="info" @click="acls(data.item)" title="Freigeben" class="mr-1" :disabled="!isOwner(data.item.acls)"><b-icon-share/></b-button>
  <!--      <b-button size="sm" variant="info" @click="postcalc(data.item)" title="Nachkalkulation" :disabled="!isWrite(data.item.acls)"><b-icon-box-arrow-up-right/></b-button>-->
      </template>
    </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilMixin from '@/mixins/util.mixin'
import { dateMixin } from '@/mixins/date.mixin'
import { authMixin } from '@/mixins/auth.mixin'
import CalcService from '@/services/calc.service'
import TheBaReSuDeButtons from '@/components/TheBaReSuDeButtons'

import _ from 'lodash'

export default {
  name: 'CalculationsTable',
  mixins: [utilMixin, dateMixin, authMixin],
  components: { TheBaReSuDeButtons },
  data () {
    return {
      calcData: { },
      calcs: [],
      cnt: 0,
      filter: null,
      filterOn: ['title', 'calculationNumber', 'clientCountryEU', 'owner', 'projectLeader', 'client', 'startDate', 'endDate'],
      sortDirection: 'asc'
    }
  },
  created () {
    console.log('CalculationsTable -> created')
    //    this.reset()
  },
  mounted: function () {
    document.getElementById('filter-input').focus()
    console.log('CalculationsTable -> mounted')
    this.reset()
  },
  methods: {
    adminlist () {
      this.$router.push('/adminlist')
    },
    newCalculation () {
      this.$router.push({ name: 'newcalculation' })
    },
    reset () {
      CalcService.listCalcs().then(
        response => {
          this.calcs = response.items
          this.cnt = response.cnt
        }
      ).catch(
        error => {
          this.makeToast(this.$i18n.t('error.text', { status: error.status, message: error.message, id: 'list' }), this.$i18n.t('result.error.title'), 'danger')
        }
      )
    },
    onFiltered (filteredItems) {
      console.log('filtered: ', filteredItems)
    },
    defaultTableField (key) {
      return {
        key: key,
        label: this.$t(key + '.label'),
        sortable: true,
        sortByFormatted: true,
        filterByFormatted: true,
        formatter: value => {
          return value || '(' + this.$t('missing.text') + ')'
        }
      }
    },
    tableFields () {
      return [
        { key: 'show', label: '' },
        this.defaultTableField('title'),
        this.defaultTableField('calculationNumber'),
        {
          ...this.defaultTableField('clientCountryEU'),
          label: 'EU',
          formatter: value => {
            if (value) { return 'Ja' }
            return 'Nein'
          }
        },
        {
          ...this.defaultTableField('owner'),
          formatter: (value, key, item) => {
            return _.map(_.filter(item.acls, { right: 'owner' }), 'name').join(', ')
          }
        },
        this.defaultTableField('projectLeader'),
        this.defaultTableField('client'),
        {
          key: 'startDate',
          label: this.$t('startDate.labelShort'),
          sortable: true,
          filterByFormatted: true,
          formatter: value => { return this.formatDateDay(value) }
        },
        {
          key: 'endDate',
          label: this.$t('endDate.labelShort'),
          sortable: true,
          filterByFormatted: true,
          formatter: value => { return this.formatDateDay(value) }
        },
        { key: 'actions', label: this.$t('actions.label'), tdClass: 'text-right', thClass: 'text-right' }
      ]
    },
    owners (acls) {
      const owners = _.map(_.filter(acls, { right: 'owner' }), 'name')
      return owners.join(', ')
    },
    isOwner (acls) {
      return (_.find(acls, acl => acl.id === this.currentUser.username && acl.right === 'owner'))
    },
    isWrite (acls) {
      return (_.find(acls, acl => acl.id === this.currentUser.username && (acl.right === 'write' || acl.right === 'owner')))
    },
    show (item) {
      // this.$emit('show', item.uuid)
      this.$router.push({ name: 'calculation', params: { id: item.uuid } })
    },
    acls (item) {
      // this.$emit('acls', item)
      this.$router.push({ name: 'acls', params: { id: item.uuid } })
    },
    clone (item) {
      //      this.$emit('clone', item.uuid)
      this.$router.push({ name: 'calculation', params: { cloneof: item.uuid } })
    },
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    }
  }
}
</script>

<style scoped>

</style>
