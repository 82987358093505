import axios from 'axios'
import store from '@/store'
import qs from 'qs'

const API_URL = process.env.VUE_APP_API_URL
const API_PATH = 'core/'
const SAML_LOGIN_URL = process.env.VUE_APP_SAML_LOGIN_URL
const LOCAL_LOGIN_URL = process.env.VUE_APP_LOCAL_LOGIN_URL
const LOGOUT_URL = process.env.VUE_APP_LOGOUT_URL

const receiveMessage = event => {
  // do we trust? might be different from what we originally opened
  const arr = window.location.href.split('/')
  const myOrigin = arr[0] + '//' + arr[2]
  if (event.origin !== myOrigin) {
    return
  }
  // console.log("Got event from " + event.origin);
  const { data } = event
  // console.log("data" + data);
  if (data === 'ak3popup') {
    window.removeEventListener('message', receiveMessage)
    store.dispatch('auth/ping')
  }
}

let windowObjectReference = null
let previousUrl = null

function popupCenter ({ url, windowName, w, h }) {
  const y = window.top.outerHeight / 2 + window.top.screenY - (h / 2)
  const x = window.top.outerWidth / 2 + window.top.screenX - (w / 2)
  return window.open(url, windowName, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`)
}

const openInWindow = (url, name) => {
  window.removeEventListener('message', receiveMessage)
  // const strWindowFeatures = 'toolbar=no, menubar=no, width=600, height=700, top=100, left=100'
  if (windowObjectReference === null || windowObjectReference.closed) {
    // windowObjectReference = window.open(url, name, strWindowFeatures)
    windowObjectReference = popupCenter({ url: url, title: name, w: 700, h: 800 })
  } else if (previousUrl !== url) {
    // windowObjectReference = window.open(url, name, strWindowFeatures)
    windowObjectReference = popupCenter({ url: url, title: name, w: 700, h: 800 })
    windowObjectReference.focus()
  } else {
    windowObjectReference.focus()
  }
  window.addEventListener('message', receiveMessage, false)
  previousUrl = url
}

class AuthService {
  defaultLogin () {
    this.samlLogin()
  }

  samlLogin () {
    openInWindow(SAML_LOGIN_URL, 'ak3auth')
    // window.location = SAML_LOGIN_URL
  }

  localLogin (username, password) {
    if (username == null || password == null) return

    const credentials = {
      username: username,
      password: password
    }
    const config = {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'application/x-www-form-urlencoded'
      },
      withCredentials: true
    }
    return axios.post(LOCAL_LOGIN_URL, qs.stringify(credentials), config).then(
      response => {
        if (response.data.error) {
          return Promise.reject(Error('wrong credentials'))
        }
        return response.data
      }
    )
    // return axios.post(LOCAL_LOGIN_URL, qs.stringify(credentials), config)
    //   .then(response => {
    //     if (response.data.error) {
    //       console.log('Unauthorized')
    //       return 'Unauthorized'
    //     } else if (response.data.success) {
    //       console.log('Success')
    //       store.dispatch('auth/ping')
    //       return 'Success'
    //     }
    //   })
    //   .catch(error => {
    //     console.log(error)
    //     return 'Error'
    //   })
  }

  logout () {
    // openInWindow(LOGOUT_URL, 'ak3auth')
    window.location = LOGOUT_URL
  }

  ping () {
    return axios.get(API_URL + API_PATH + 'ping', { withCredentials: true }).then(
      response => {
        return response.data
      }
    )
  }

  index () {
    return axios.get(API_URL + API_PATH + 'index', { withCredentials: true }).then(
      response => {
        return response.data
      }
    )
  }

  replay (config) {
    return axios.request(config)
  }
}

export default new AuthService()
