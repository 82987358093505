<template>
  <div>
    play with me {{ debug }}<br/>
    {{ dummies }}<b-button @click="outer">outer</b-button>
<!--    <Dummy v-model="dummies"/>-->
<!--    same-->
<!--    <Dummy v-bind:value="dummies" v-on:input="dummies = $event"/>-->
<!--    <Dummy :value="dummies" @input="dummies = $event"/>-->

<!--    <Dummy :dummies="dummies" @update:dummies="dummies = $event"/>-->
<!--    same-->
<!--    <Dummy :dummies.sync="dummies"/>-->
    <Dummy :dummies.sync="dummies" @addemployee="dummies.splice(0, 1, $event)"/>

    {{ employee }}
    <b-button v-b-modal.modal-1>Launch demo modal</b-button>

    <b-modal id="modal-1" title="BootstrapVue">
      <TheEmployee :empldata="employee"/>
    </b-modal>
  </div>
</template>

<script>

import TheEmployee from '@/components/TheEmployee'
import Dummy from '@/components/Dummy'
export default {
  name: 'Playground',
  components: {
    Dummy,
    TheEmployee
  },
  data () {
    return {
      foo: 'bar',
      message: 'hello',
      employee: {
        employees: []
      },
      dummies: ['one', 'two'],
      debug: null
    }
  },
  computed: {
    fooize () {
      return this.foo.toLocaleUpperCase()
    },
    barrize () {
      return this.$i18n.t('huhu')
    }
  },
  methods: {
    outer () {
      if (this.dummies) {
        this.dummies.push('outer' + this.dummies.length)
      }
    },
    foomatic () {
      this.foo = 'foomatic'
    }
  }
}
</script>

<style scoped>

</style>
