import _ from 'lodash'
import EmpcostService from '@/services/empcost.service'

export default {
  data () {
    return {
      staffFields: [{ key: 'payGrade', label: this.$t('employee.payGrade.abbr.label') },
        { key: 'description', label: this.$t('employee.description.label') },
        { key: 'percentage', formatter: 'formatNumber', label: this.$t('employee.percentage.label'), tdClass: 'text-center', thClass: 'text-center' },
        { key: 'months', formatter: 'formatNumber', label: this.$t('employee.months.label') },
        {
          key: 'cost',
          label: this.$t('cost.label'),
          tdClass: 'text-right',
          thClass: 'text-right',
          formatter: (value, key, item) => { return this.formatEuro(this.empCost(item)) }
        },
        { key: 'actions', label: this.$t('actions.label'), tdClass: 'text-right', thClass: 'text-center' }],

      hkFields: [{ key: 'type', formatter: 'translate', label: this.$t('hk.type.label') },
        { key: 'description', label: this.$t('hk.description.label') },
        { key: 'hpw', label: this.$t('hk.hpw.label') },
        { key: 'months', formatter: 'formatNumber', label: this.$t('hk.months.label') },
        {
          key: 'cost',
          formatter: (value, key, item) => {
            return this.formatEuro(this.empCost(item))
          },
          label: this.$t('cost.label'),
          tdClass: 'text-right',
          thClass: 'text-right'
        },
        { key: 'actions', label: this.$t('actions.label'), tdClass: 'text-right', thClass: 'text-center' }],
      materialFields: [
        { key: 'description', label: this.$t('description.label') },
        { key: 'amount', formatter: 'formatEuro', label: this.$t('amount.label'), tdClass: 'text-right', thClass: 'text-right' },
        { key: 'actions', label: this.$t('actions.label'), tdClass: 'text-right', thClass: 'text-right' }],
      machineCostFields: [
        { key: 'description', label: this.$t('description.label') },
        { key: 'amount', formatter: 'formatEuro', label: this.$t('amount.label'), tdClass: 'text-right', thClass: 'text-right' },
        { key: 'actions', label: this.$t('actions.label'), tdClass: 'text-right', thClass: 'text-right' }]
    }
  },
  methods: {
    doRound (val, roundFull = true) {
      if (roundFull) {
        return Math.round(val)
      } else {
        return val
      }
    },
    uuidv4 () {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0; const v = c === 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
      })
    },
    confirmBoxOptions () {
      return {
        title: this.$t('confirm.label'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t('confirmYes.label'),
        cancelTitle: this.$t('confirmCancel.label'),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }
    },
    capitalize (str) {
      return _.capitalize(str)
    },
    formatNumber (val) {
      return new Intl.NumberFormat('de-DE').format(val)
    },
    formatDate (val) {
      // OMG
      return val.substr(-2, 2) + '.' + val.substr(-5, 2) + '.' + val.substr(0, 4)
    },
    formatEuro (val) {
      return this.formatFullEuro(val)
      // return new Intl.NumberFormat('de-DE', {
      //   style: 'currency',
      //   currency: 'EUR'
      // }).format(val)
    },
    formatFullEuro (val) {
      return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(val)
    },
    translate (val) {
      return this.$t(val)
    },
    sc () {
      return this.calcdata.surcharge
    },
    empCost (employee, roundFull = false) {
      const empCost = EmpcostService.empCost(employee)
      if (roundFull) {
        return Math.round(empCost)
      } else {
        return Math.round(empCost * 100) / 100
      }
    },
    employeeCostByPaymentType (paymentType, roundFull = true) {
      const t = this
      const res = _.sum(_.map(this.calcdata.employees, function filter (employee) {
        if (employee.paymentType === paymentType) {
          return t.empCost(employee, roundFull)
        }
      })
      )
      if (res == null) {
        return 0
      }
      return res
    },
    pfEmployeeCost (roundFull = true) {
      return this.employeeCostByPaymentType('project', roundFull)
    },
    efEmployeeCost (roundFull = true) {
      return this.employeeCostByPaymentType('existing', roundFull)
    },
    totalEmployeeCost (roundFull = true) {
      return _.sum(_.map(this.calcdata.employees, (employee) =>
        this.empCost(employee, roundFull)))
    },
    addOverheadSurcharge (sum) {
      if (this.calcdata.overheadSurcharge == null) {
        return 0
      }
      return sum + ((sum + Number.EPSILON) * Number(this.calcdata.overheadSurcharge) / 100)
    },
    totalOverheadSurchargeCost (roundFull = true) {
      const val = (this.totalEmployeeCost(roundFull) + Number.EPSILON) * Number(this.calcdata.overheadSurcharge) / 100
      return this.doRound(val, roundFull)
    },
    totalEmployeeResearchBonusCost (roundFull = true) {
      const val = this.totalEmployeeCost(roundFull) + this.totalOverheadSurchargeCost(roundFull) + Number(this.calcdata.researchBonus)
      return this.doRound(val, roundFull)
    },
    totalMaterialCost (roundFull = true) {
      if (roundFull) {
        return _.sum(_.map(this.calcdata.materials, (mat) => Math.round(Number(mat.amount))))
      } else {
        return _.sum(_.map(this.calcdata.materials, (mat) => Number(mat.amount)))
      }
    },
    totalMachineCost (roundFull = true) {
      if (roundFull) {
        return _.sum(_.map(this.calcdata.machineCosts, (mat) => Math.round(Number(mat.amount))))
      } else {
        return _.sum(_.map(this.calcdata.machineCosts, (mat) => Number(mat.amount)))
      }
    },
    fullCost (roundFull = true) {
      return (Number(this.totalEmployeeResearchBonusCost(roundFull)) +
        Number(this.totalMaterialCost(roundFull))) +
        Number(this.totalMachineCost(roundFull))
    },
    profitSurcharge (roundFull = true) {
      const val = Number((this.fullCost(roundFull) + Number.EPSILON) * Number(this.calcdata.profitSurcharge) / 100)
      return this.doRound(val, roundFull)
    },
    offerprice (roundFull = true) {
      return Number(this.fullCost(roundFull) + this.profitSurcharge(roundFull))
    },
    vat (roundFull = true) {
      const val = Number((this.offerprice(roundFull) + Number.EPSILON) * 19 / 100)
      return this.doRound(val, roundFull)
    },
    bruttoOfferprice (roundFull = true) {
      return Number(this.offerprice(roundFull) + this.vat(roundFull))
    }
  }
}
