<template>
  <div class="border border-dark">
    Dummy {{ dummies }}
    <div v-if="myDummies">
      <p v-for="dummy in myDummies" :key="String(dummy)">dummy {{ typeof dummy}}</p>
    </div>
    <div v-if="foos">
      <p v-for="foo in foos" :key="foo">foo {{foo}}</p>
    </div>
    <b-button @click="magic">magic</b-button>
  </div>
</template>

<script>
// import CostEmployee from '@/models/cost.employee'
import _ from 'lodash'

export default {
  name: 'Dummy',
  props: {
    dummies: {}
  },
  data () {
    return {
      foos: []
    }
  },
  computed: {
    myDummies () {
      return _.filter(this.dummies, o => {
        if (o.type === 'foo') {
          return true
        }
        return false
      })
    }
  },
  methods: {
    magic () {
      // this.$emit('addemployee', new CostEmployee('foo'))
      this.$emit('addemployee', { type: 'foo' })
      // this.value = ['inner']
      // this.$emit('update:dummies', ['inner'])
      // if (this.dummies) {
      //   this.dummies.push('horst' + this.dummies.length)
      // }
      // this.foos.push('horst' + this.foos.length)
    }
  }
}
</script>

<style scoped>

</style>
