
export const authMixin = {
  computed: {
    currentUser () {
      return this.$store.state.auth.user
    }
  },
  methods: {
    hasAnyRole (roles) {
      if (this.currentUser && this.currentUser.roles) {
        let result = false
        roles.forEach(role => {
          if (this.currentUser.roles.includes(role)) {
            result = true
          }
        })
        return result
      }
      return false
    },
    isInternalAudit () {
      return this.hasAnyRole(['ROLE_SUPERADMIN', 'ROLE_INTERNALAUDIT'])
    },
    isCalcadvisor () {
      return this.hasAnyRole(['ROLE_SUPERADMIN', 'ROLE_CALCADVISOR'])
    }
  }
}
