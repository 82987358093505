<template>
  <div v-frag>
    <b-tr>
      <b-td colspan="4" class="font-weight-bold">{{ $t('sum.label') }}</b-td>
      <b-td class="text-right font-weight-bold">{{ formatEuro(totalCost) }}</b-td>
      <b-td></b-td>
    </b-tr>
    <b-tr v-if="overheadSurcharge">
      <b-td colspan="4" class="font-weight-bold">{{ $t('overheadSurchargeSum.label', {percent: overheadSurcharge, pscsum: formatEuro(totalOverheadSurcharge)}) }}</b-td>
      <b-td class="text-right font-weight-bold">{{ formatEuro(totalSumOverheadSurcharge) }}</b-td>
      <b-td></b-td>
    </b-tr>
    <b-tr v-else>
      <b-td colspan="6" class="font-weight-bold text-danger">{{ $t('overheadSurchargeSum.notcalculatable.label') }}</b-td>
    </b-tr>
  </div>
</template>
<script>
import utilMixin from '@/mixins/util.mixin'
import frag from 'vue-frag'

export default {
  name: 'TheEmplyeeTableSumsFooter',
  mixins: [utilMixin],
  directives: {
    frag
  },
  props: {
    totalCost: Number,
    overheadSurcharge: Number,
    totalOverheadSurcharge: Number,
    totalSumOverheadSurcharge: Number
  }
}
</script>

<style scoped>

</style>
