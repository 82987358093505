<template>
  <div>
    <b-card class="mt-4">
      <template #header>
        <h6>{{  $t("headingHk"+capitalize(paymentType)) }}</h6>
      </template>
      <template>
        <b-alert :show="alertData.text != null" :variant="alertData.variant">{{ alertData.text }}</b-alert>
        <b-card>
          <b-container fluid>
            <b-row class="my-1" v-for="(value, name) in hkDefs" :key="name">
              <b-col sm="4">
                <label :for="`field-${id}-${name}`">{{ $t(`hk.${name}.label`) }}</label>
              </b-col>
              <b-col sm="8">
                <b-form-select v-if="value.type === 'select'" :id="`field-${id}-${name}`" v-model="hkTemplate[name]" :options="value.options"  :state="fieldState(name)"></b-form-select>
                <b-form-input v-else-if="value.type === 'number'" :id="`field-${id}-${name}`" :type="value.type" v-model="hkTemplate[name]" lang="de" step="any" :state="fieldState(name)" ></b-form-input>
                <b-form-input v-else :id="`field-${id}-${name}`" :type="value.type" v-model="hkTemplate[name]" :state="fieldState(name)"></b-form-input>
                <b-form-invalid-feedback :id="`field-${id}-${name}-input-live-feedback`">
                  {{ $t(`hk.${name}.error`) }}
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
<!--            Actions -->
            <b-row>
              <b-col sm="4">
              </b-col>
              <b-col sm="8">
                <span v-if="edit">
                  <b-button size="sm" variant="success" @click="updateHK(hkTemplate.uuid)">{{ $t("button.save.label") }}</b-button>
                  <b-button size="sm" class="ml-2" variant="danger" @click="cancelEdit">{{ $t("button.cancel.label") }}</b-button>
                </span>
                <span v-else>
                  <b-button size="sm" variant="success" @click="addHK">{{ $t("button.add.label") }}</b-button>
                </span>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </template>
    </b-card>
<!--    Table of HK Employees-->
    <b-card v-if="myHKs.length" class="mt-2">
      <b-table  class="mb-2 mt-2" small striped hover :items="myHKs" :fields="hkFields">
        <template #cell(actions)="data">
          <span v-if="!edit || data.item.uuid !== hkTemplate.uuid">
            <b-button :disabled="edit" @click="editHK(data.item.uuid)" variant="info" size="sm">
              <b-icon-pencil />
            </b-button>
            <b-button :disabled="edit" class="ml-2" @click="removeHK(data.item.uuid)" variant="danger" size="sm">
              <b-icon-trash />
            </b-button>
          </span>
          <span v-else>{{$t('inEditMode.label')}}
            <b-button disabled variant="secondary" size="sm">
              <b-icon-lock />
            </b-button>
          </span>
        </template>
        <!--            Sums Footer -->
        <template v-slot:custom-foot>
          <b-tr>
            <b-td colspan="4" class="font-weight-bold">{{ $t('sum.label') }}</b-td>
            <b-td class="text-right font-weight-bold">{{ formatEuro(totalCost) }}</b-td>
            <b-td></b-td>
          </b-tr>
          <b-tr v-if="calcdata.overheadSurcharge">
            <b-td colspan="4" class="font-weight-bold">{{ $t('overheadSurchargeSum.label', {percent: calcdata.overheadSurcharge, pscsum: formatEuro(totalOverheadSurcharge)}) }}</b-td>
            <b-td class="text-right font-weight-bold">{{ formatEuro(totalSumOverheadSurcharge) }}</b-td>
            <b-td></b-td>
          </b-tr>
          <b-tr v-else>
            <b-td colspan="6" class="font-weight-bold text-danger">{{ $t('overheadSurchargeSum.notcalculatable.label') }}</b-td>
          </b-tr>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>

import UtilService from '@/services/util.service'
import utilMixin from '@/mixins/util.mixin'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import _ from 'lodash'

export default {
  name: 'TheHKEmployee',
  mixins: [utilMixin],
  setup () {
    return { v$: useVuelidate() }
  },
  props: {
    calcdata: {
      type: Object,
      required: true
    },
    paymentType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      id: null,
      edit: false,
      hkTemplate: this.freshHKTemplate(),
      alertData: {
        variant: 'info',
        text: null
      },
      hkDefs: {
        type: {
          type: 'select',
          options: this.hkTypeOptions()
        },
        hpw: {
          type: 'select',
          options: this.hpwOptions()
        },
        months: { type: 'number' },
        description: { type: 'text' }
      }
    }
  },
  validations () {
    const gtZero = (value) => value != null && Number(value) > 0
    return {
      hkTemplate: {
        type: { required },
        description: { required },
        months: { required, gtZero }
      }
    }
  },
  computed: {
    myHKs () {
      return _.filter(this.calcdata.employees, (emp) => {
        return emp.employmentType === 'hk' && emp.paymentType === this.paymentType
      })
    },
    totalCost (collection) {
      return _.sum(_.map(this.myHKs, (hk) => this.empCost(hk)))
    },
    totalOverheadSurcharge () {
      if (this.calcdata.overheadSurcharge == null) {
        return null
      }
      return (this.totalCost + Number.EPSILON) * Number(this.calcdata.overheadSurcharge) / 100
    },
    totalSumOverheadSurcharge () {
      return Number(this.totalCost + this.totalOverheadSurcharge)
    }
  },
  mounted () {
    this.id = this._uid
  },
  methods: {
    hpwOptions () {
      return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]
    },
    hkTypeOptions () {
      const hkTypes = Object.keys(this.$store.state.calc.hkSalary.values)
      const types = [] // [{ value: null, text: this.$t('selectHint.label') }]
      hkTypes.forEach(type =>
        types.push({ value: type, text: this.$t(type + '.label') })
      )
      return types
    },
    fieldState (field) {
      if (this.v$.hkTemplate[field] == null || !this.v$.hkTemplate[field].$dirty) {
        return null
      }
      return !this.v$.hkTemplate[field].$error
    },
    addHK () {
      this.v$.$touch()
      if (this.v$.$error) return
      this.calcdata.employees.push(this.hkTemplate)
      this.alertData.text = this.$t('entry.saved.label')
      this.resetHK()
    },
    editHK (uuid) {
      const hk = _.find(this.calcdata.employees, o => o.uuid === uuid)
      this.edit = true
      this.hkTemplate.type = hk.type
      this.hkTemplate.hpw = hk.hpw
      this.hkTemplate.description = hk.description
      this.hkTemplate.months = hk.months
      this.hkTemplate.uuid = uuid
      this.alertData = { text: this.$t('entry.editHint.label'), variant: 'info' }
    },
    updateHK (uuid) {
      this.v$.$touch()
      if (this.v$.$error) return
      const hk = _.find(this.calcdata.employees, o => o.uuid === uuid)
      this.edit = false
      hk.type = this.hkTemplate.type
      hk.hpw = this.hkTemplate.hpw
      hk.description = this.hkTemplate.description
      hk.months = this.hkTemplate.months
      this.alertData = { text: this.$t('entry.saved.label'), variant: 'info' }
      this.resetHK()
    },
    removeHK (uuid) {
      this.$bvModal.msgBoxConfirm(this.$t('deleteConfirm.message'), this.confirmBoxOptions())
        .then(value => {
          if (value === true) {
            const idx = _.findIndex(this.calcdata.employees, o => o.uuid === uuid)
            this.calcdata.employees.splice(idx, 1)
            this.alertData = { text: this.$t('entry.deleted.label'), variant: 'info' }
          } else {
            this.alertData.text = null
          }
        })
    },
    cancelEdit () {
      this.resetHK()
      this.edit = false
      this.alertData.text = null
    },
    resetHK () {
      this.hkTemplate = this.freshHKTemplate()
      this.v$.$reset()
    },
    freshHKTemplate () {
      return {
        type: 'shk',
        description: null,
        hpw: 19,
        months: 1,
        employmentType: 'hk',
        paymentType: this.paymentType,
        uuid: UtilService.uuidv4()
      }
    }
  }
}
</script>

<style scoped>

</style>
