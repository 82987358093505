<template>
  <div class="home mx-3">
    <h1>Barrierefreiheit</h1>
    <p> Der Anbieter dieses Webangebots (Zentrale Universitätsverwaltung) ist bemüht, das Angebot im Einklang mit der <a href="http://gesetze-bayern.de/Content/Document/BayBGG/true">gültigen Gesetzgebung</a> barrierefrei zugänglich zu machen.<br/>
      Diese Erklärung zur Barrierefreiheit gilt für die Webauftritte und Anwendungen, die unter www.angebotskalkulation.zuv.fau.de zugänglich ist.</p>

    <h2>Stand der Vereinbarkeit mit den Anforderungen ("Konformitätsstatus")</h2>
    Teilweise konform: Einige Teile des Inhalts entsprechen nicht vollständig dem Standard für Barrierefreiheit
  <p>
    <span class="font-weight-bold">Methodik der Prüfung:</span> Selbstbewertung<br/>
    <span class="font-weight-bold">Erstellungsdatum: 28.11.2022</span><br/>
    <span class="font-weight-bold">Letzte Überprüfung: 28.11.2022</span><br/>
  </p>
    <h2>Bekannte Probleme bei der Barrierefreiheit</h2>
    Auflistung und Erläuterung der Probleme bei der Umsetzung der Barrierefreiheit dieses Webauftritts.
    <h3>Nicht barrierefrei zugängliche Inhalte</h3>
    <ol>
      <li>Die Sitzungdauer (Session) der Anwendungen mit Login ist systemseitig auf 30 Minuten festgelegt.<br/>
        Wenn Sie also länger als 30 Minuten nicht in Ihrer Anwendung aktiv sind, werden Sie aus Sicherheitsgründen automatisch abgemeldet.</li>
      <li>An einigen Stellen fehlen in den Anwendungen alternative Texte für Grafiken/Icons.</li>
      <li>Bei Verlinkungen fehlen zusätzliche Beschreibungen.</li>
    </ol>
    <h3>Alternative Zugangswege</h3>
    <p>Falls Sie bei der Benutzung unserer Anwendung auf Schwierigkeiten stoßen und Unterstützung benötigen, kontaktieren Sie uns! Die Kontaktinformationen finden Sie im nachfolgenden Abschnitt "Ansprechpartner".</p>
    <h3>Hilfe und Kontaktaufnahme bei Problemen</h3>
    <p> Bitte teilen Sie uns Mängel bei der Einhaltung der Anforderungen an die Barrierefreiheit mit. Darüber hinaus können Sie Informationen, die nicht barrierefrei dargestellt werden, barrierefrei anfordern.</p>
    <h4>Ansprechpartner</h4>
    <p>Axel Klon<br/>
    Tel.: +49 9131 85-70810<br/>
    91056 Erlangen<br />
    E-Mail: <a href="mailto:axel.klon@fau.de">axel.klon@fau.de</a>
    </p>
    <h2>Durchsetzungsverfahren</h2>
    <p>Bleibt eine Anfrage über die Kontaktmöglichkeit innerhalb von sechs Wochen ganz oder teilweise unbeantwortet, prüft die zuständige Aufsichtsbehörde auf Antrag des Nutzers, ob im Rahmen der Überwachung gegenüber den Betreibern des Webangebots Maßnahmen erforderlich sind.
      <br/>
      Kontaktinformation der für das Durchsetzungsverfahren zuständigen Aufsicht:</p>
    <p>
      Landesamt für Digitalisierung, Breitband und Vermessung<br/>
      Alexandrastraße 4<br/>
      80538 München<br/>
      Telefon: +49 89 2129-1111<br/>
      Fax: +49 89 2129-1113<br/>
      E-Mail: <a href="mailto:ervice@geodaten.bayern.de">service@geodaten.bayern.de</a><br/>
      Web: <a href="https://www.ldbv.bayern.de/">https://www.ldbv.bayern.de/</a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'Accessibility',
  mounted: function () {
    this.scrollToTop()
  },
  methods: {
    scrollToTop () {
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style lang="scss">
.home {
  min-height: calc(100vh - 161px);
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

</style>
