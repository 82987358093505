<template>
  <b-card :header="$t('comp.login.local.label')">
    <b-card-text>{{ $t('comp.login.local.description') }}</b-card-text>
    <b-form>
      <b-form-group :label="$t('comp.login.local.username.label')"
                    :description="$t('comp.login.local.username.description')">
        <b-form-input id="username" name="username" v-model="username" type="text" autocomplete="off" trim/>
      </b-form-group>
      <b-form-group :label="$t('comp.login.local.password.label')"
                    :description="$t('comp.login.local.password.description')">
        <b-form-input id="password" name="password" v-model="password" type="password" autocomplete="off" trim/>
      </b-form-group>

      <b-button @click="localLogin" type="submit" variant="primary">
        {{ $t('comp.login.button.label') }}
      </b-button>
    </b-form>
  </b-card>
</template>

<script>
import AuthService from '@/services/auth.service'

export default {
  name: 'LocalLogin',
  data () {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    localLogin (event) {
      event.preventDefault()
      AuthService.localLogin(this.username, this.password)
        .then(() => {
          this.makeToast(
            this.$i18n.t('comp.login.success.description'),
            this.$i18n.t('comp.login.success.title'),
            'info'
          )
          this.$store.dispatch('auth/ping').then(() => this.$emit('success'))
        })
        .catch(error => {
          this.makeToast(
            this.$i18n.t('error.text', { status: error.status, message: error.message }),
            this.$i18n.t('result.error.title'),
            'danger')
        })
    },
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    }
  }
}
</script>

<style scoped>

</style>
