<template>
  <multiselect :value="value" @input="$emit('input', $event)" :options="options" track-by="id" label="name" :loading="isUserLoading" :internal-search="false" @search-change="debouncedFindUser" :placeholder="$t('acl.user.placeholder')">
    <template v-slot:singleLabel="{ option }">
      {{ option.name }} <template v-if="option.ou">({{ option.ou }})</template> <template v-if="option.mail">&lt;{{ option.mail }}&gt;</template> <span class="text-secondary">{{ option.id }}</span>
    </template>
    <template v-slot:option="{ option }">
      {{ option.name }} &lt;{{ option.mail }}&gt; ({{ option.id }}) <template v-if="option.ou">{{ option.ou }}</template>
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
import _ from 'lodash'
import CalcService from '@/services/calc.service'

export default {
  name: 'CalculationUserSelect',
  props: {
    value: {
      type: Object
    },
    ou: {
      type: String
    }
  },
  components: {
    Multiselect
  },
  data () {
    return {
      options: [],
      isUserLoading: false,
      debouncedFindUser: null
    }
  },
  created () {
    this.debouncedFindUser = _.debounce(this.findUser, 500)
  },
  methods: {
    limitText (count) {
      return `and ${count} other countries`
    },
    findUser (query) {
      this.isUserLoading = true
      const msgId = 'Nutzersuche'
      const params = { filter: query, ou: this.ou }
      return CalcService.findUser(params).then(
        response => {
          this.options = response
        }
      ).catch(
        error => this.makeToast(this.$t('error.text', { id: msgId, status: error.status, message: error.message }), this.$t('result.error.title'), 'danger')
      ).finally(
        () => {
          this.isUserLoading = false
        }
      )
    },
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    }
  }
}
</script>

<style scoped>

</style>
