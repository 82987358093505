<template>
  <div id="app" v-if="!popup">
    <b-modal v-model="showLocalLoginModal" ok-only ok-title="cancel" @hidden="cleanUnauthorizedRequests">
      <LocalLogin @success="hideLocalLogin"/>
    </b-modal>
    <TheHeader :showlocallogin.sync="showLocalLoginModal"/>
    <div>
      <router-view/>
    </div>
    <TheFooter/>
<!--    <b-alert fade variant="warning" class="position-fixed fixed-bottom m-0 rounded-0" style="z-index: 2000;" show v-if="offline">OFFLINE</b-alert>-->
<!--    <b-alert fade variant="danger" class="position-fixed fixed-bottom m-0 rounded-0" style="z-index: 2000;" show v-else-if="serverUnavailable">NO CONNECTION</b-alert>-->
  </div>
</template>

<script>
import store from './store'
import { authMixin } from '@/mixins/auth.mixin'
import LocalLogin from '@/components/LocalLogin'
import TheFooter from '@/components/TheFooter'
import TheHeader from '@/components/TheHeader'

const onOffListener = () => {
  store.dispatch('auth/ping')
}

// const preventNav = (event) => {
//   if (store.state.pendingChanges.length < 1) {
//     return
//   }
//   event.preventDefault()
//   // Chrome requires returnValue to be set.
//   event.returnValue = ''
// }

export default {
  name: 'App',
  components: { TheHeader, LocalLogin, TheFooter },
  mixins: [authMixin],
  data: function () {
    return {
      popup: false,
      showLoginModal: false,
      showLocalLoginModal: false,
      nightMode: false
    }
  },
  computed: {
    offline () {
      return !this.$store.state.auth.status.clientOnline
    },
    serverUnavailable () {
      return !this.$store.state.auth.status.serverOnline
    },
    unauthorizedModal () {
      return this.$store.state.auth.unauthorized.modal
    }
  },
  watch: {
    unauthorizedModal: function (newValue) {
      if (newValue === true) {
        this.showMsgBoxLogin()
      }
    },
    nightMode (newValue) {
      if (newValue) {
        document.body.setAttribute('data-theme', 'night')
      } else {
        document.body.removeAttribute('data-theme')
      }
    }
  },
  created () {
    // const params = window.location.search;
    if (window.opener) {
      this.popup = true
      window.opener.postMessage('ak3popup')
      window.close()
    } else {
      window.addEventListener('online', onOffListener)
      window.addEventListener('offline', onOffListener)
      // window.addEventListener('beforeunload', preventNav)
      this.ping()
    }
  },
  destroyed () {
    window.removeEventListener('online', onOffListener)
    window.removeEventListener('offline', onOffListener)
    // window.removeEventListener('beforeunload', preventNav)
  },
  methods: {
    ping () {
      this.$store.dispatch('auth/ping')
    },
    hideLocalLogin () {
      this.showLocalLoginModal = false
    },
    cleanUnauthorizedRequests () {
      if (!this.currentUser) {
        this.$store.dispatch('auth/declineUnauthorizedRequest')
      }
    },
    showMsgBoxLogin () {
      if (this.currentUser && !this.currentUser.saml) {
        this.$store.dispatch('auth/confirmUnauthorizedRequest', () => {
          this.showLocalLogin()
        })
      } else {
        this.$bvModal.msgBoxConfirm(this.$i18n.t('unauthorized.text'), {
          title: this.$i18n.t('unauthorized.title'),
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          okTitle: this.$i18n.t('signin.label'),
          cancelTitle: this.$i18n.t('cancel.label'),
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
          .then(value => {
            if (value) {
              this.$store.dispatch('auth/confirmUnauthorizedRequest')
            } else {
              this.$store.dispatch('auth/declineUnauthorizedRequest')
            }
          })
          .catch(err => {
            // An error occurred
            console.log(err)
          })
      }
    }
  }
}
</script>

<style lang="scss">
#app {
  // Make this better!!!
  min-height: calc(100vh);
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

</style>
