<template>
<div>
  <h2>{{ $t("headingEmployees") }}</h2>
  <b-card>
    <template #header>
      <h5>{{  $t("headingPFEmployees") }}</h5>
    </template>
    <TheStaffEmployee :calcdata="calcdata" paymentType="project" />
    <TheHKEmployee :calcdata="calcdata" paymentType="project" />
  </b-card>
  <b-card class="mt-2">
    <template #header>
      <h5>{{  $t("headingBFEmployees") }}</h5>
      <span class="font-weight-bold">{{ $t('hint.label')}}: </span>{{ $t('headingBFEmployees.hint.label')}}
    </template>
    <TheStaffEmployee :calcdata="calcdata" paymentType="existing" />
    <TheHKEmployee :calcdata="calcdata" paymentType="existing" />
  </b-card>
  <b-card class="mt-2 mb-4">
    <template #header>
      <h5>{{  $t("headingResearchBonus") }}</h5>
    </template>
    <TheResearchBonus :calcdata="calcdata" />
  </b-card>
<!--  <b-button @click="scrollToTop">TOP</b-button>-->
</div>
</template>

<script>
import TheStaffEmployee from '@/components/TheStaffEmployee'
import TheHKEmployee from '@/components/TheHKEmployee'
import TheResearchBonus from '@/components/TheResearchBonus'

export default {
  name: 'TheEmployee',
  components: { TheResearchBonus, TheStaffEmployee, TheHKEmployee },
  props: {
    calcdata: {
      type: Object,
      required: true
    }
  },
  mounted: function () {
    this.scrollToTop()
  },
  methods: {
    scrollToTop () {
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style scoped>

</style>
