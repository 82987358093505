import axios from 'axios'

class DefaultApiService {
  API_URL = process.env.VUE_APP_API_URL; // /ak3/api/

  // in calcService: calc/
  // ---> this.API_URL + this.apiPath ist /ak3/api/calc/ (ApiCalcController)
  constructor (apiPath) {
    if (apiPath === undefined) {
      this.apiPath = 'core/'
    } else {
      this.apiPath = apiPath
    }
  }

  list (endpoint, params) {
    // console.log('DefaultApiService list axios url: ' + this.API_URL + this.apiPath + endpoint)
    return axios.get(this.API_URL + this.apiPath + endpoint, { params: params, withCredentials: true }).then(
      response => {
        return response.data
      },
      error => this.errorHandler(error)
    )
  }

  get (endpoint, id, params) {
    let url = this.API_URL + this.apiPath + endpoint
    if (id) {
      url += '/' + id
    }
    return axios.get(url, { params: params, withCredentials: true }).then(
      response => {
        return response.data
      },
      error => this.errorHandler(error)
    )
  }

  create (endpoint, data, params) {
    return axios.post(this.API_URL + this.apiPath + endpoint, data, { params: params, withCredentials: true }).then(
      response => {
        return response.data
      },
      error => this.errorHandler(error)
    )
  }

  update (endpoint, data, params) {
    return axios.put(this.API_URL + this.apiPath + endpoint, data, { params: params, withCredentials: true }).then(
      response => {
        return response.data
      },
      error => this.errorHandler(error)
    )
  }

  delete (endpoint, id, params) {
    return axios.delete(this.API_URL + this.apiPath + endpoint + '/' + id, { params: params, withCredentials: true }).then(
      response => {
        return response.data
      },
      error => this.errorHandler(error)
    )
  }

  count (endpoint, params) {
    return axios(this.API_URL + this.apiPath + endpoint, { params: params, withCredentials: true }).then(
      response => {
        return response.data.data
      },
      error => this.errorHandler(error)
    )
  }

  errorHandler (error) {
    const customError = {}
    if (error.response) {
      customError.data = error.response.data
      customError.message = error.response.data.exception
      customError.status = error.response.status
      customError.headers = error.response.headers
    } else {
      customError.noResponse = true
      customError.message = error.message
    }
    if (error.request) {
      customError.request = error.request
    }
    customError.config = error.config
    customError.json = error.toJSON()
    return Promise.reject(customError)
  }
}

export default DefaultApiService
