<template>
  <div class="home">
    <h1 class="sr-only">{{ $t('comp.home.title') }}</h1>
    <b-jumbotron>
      <template #header>{{ $t('app.title') }}</template>

<!--      <template #lead><a href="http://www.idm.fau.de" target="_blank"><b-img src="../assets/icons/logo/idm.png" height="24" alt="RRZE.IdentityManagment"></b-img></a> {{ $t('app.description') }}</template>-->

      <hr class="my-4">

<!--      <blockquote class="blockquote m-5 text-right">-->
<!--        <p class="mb-0">{{ $t('comp.home.quote.text') }}</p>-->
<!--        <footer class="blockquote-footer">{{ $t('comp.home.quote.source.text') }} <cite title="Source Title">{{ $t('comp.home.quote.source.title') }}</cite></footer>-->
<!--      </blockquote>-->

      <div v-if="!currentUser" class="row">
        <div class="col text-center">
          <b-button @click="login" size="lg" class="text-center"><b-icon-box-arrow-in-right/>{{ $t('signin.button.label') }}</b-button>
        </div>
      </div>
      <div v-else>
        <b-button @click="jumpTo('list')" v-if="showCalculationBoard" variant="primary" class="mr-1 mb-1 text-left">{{ $t('home.calculation.label') }}</b-button>
        <b-button @click="jumpTo('adminlist')" v-if="isInternalAudit()" variant="primary" class="mr-1 mb-1 text-left">{{ $t('home.adminlist.label') }}</b-button>
        <b-button @click="jumpTo('newcalculation')" variant="success" class="mr-1 mb-1 text-left"><b-icon-plus/> {{ $t('add.label', [$t('calculation')]) }}</b-button>
        <b-alert show variant="warning" v-if="showGoAway">{{ $t('successful.login.but.no.rights.text') }}</b-alert>
        <b-button @click="logout" v-if="showGoAway" variant="primary" class="mr-1 mb-1 text-left">{{ $t('signout.label') }}</b-button>
      </div>
    </b-jumbotron>
  </div>
</template>

<script>
import { authMixin } from '@/mixins/auth.mixin'
import AuthService from '@/services/auth.service'

export default {
  name: 'Home',
  components: {},
  mixins: [authMixin],
  computed: {
    showCalculationBoard () {
      return this.hasAnyRole(['ROLE_USER_FAU'])
    },
    showGoAway () {
      return !this.hasAnyRole(['ROLE_USER_FAU'])
    }
  },
  methods: {
    login () {
      AuthService.defaultLogin()
    },
    logout () {
      AuthService.logout()
    },
    jumpTo (name, params) {
      if (params === undefined) {
        this.$router.push({ name: name })
      } else {
        this.$router.push({ name: name, params: params })
      }
    }
  }
}
</script>
<style lang="scss">
.home {
  min-height: calc(100vh - 161px);
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

</style>
