import _ from 'lodash'
import AuthService from '@/services/auth.service'
import CalcService from '@/services/calc.service'

export const auth = {
  namespaced: true,
  state: {
    status: {
      clientOnline: true,
      serverOnline: true,
      loggedIn: false
    },
    unauthorized: {
      inProgress: false,
      modal: false,
      confirmed: false,
      deferreds: []
    },
    user: null
  },
  mutations: {
    clientOnline (state) {
      state.status.clientOnline = true
    },
    clientOffline (state) {
      state.status.clientOnline = false
    },
    serverOnline (state) {
      state.status.serverOnline = true
    },
    serverOffline (state) {
      state.status.serverOnline = false
    },
    loginSuccess (state, user) {
      state.status.loggedIn = true
      state.user = user
    },
    logoutSuccess (state) {
      state.status.loggedIn = false
      state.user = null
    },
    unauthorizedRequest (state, deferred) {
      state.unauthorized.inProgress = true
      state.unauthorized.modal = true
      state.unauthorized.confirmed = false
      state.unauthorized.deferreds = _.concat(state.unauthorized.deferreds, deferred)
    },
    confirmedUnauthorizedRequest (state) {
      state.unauthorized.modal = false
      state.unauthorized.confirmed = true
    },
    declinedUnauthorizedRequest (state) {
      state.unauthorized.inProgress = false
      state.unauthorized.modal = false
      state.unauthorized.confirmed = false
      state.unauthorized.deferreds = []
    },
    processedDeferredUnauthorizedRequest (state) {
      if (!_.isEmpty(state.unauthorized.deferreds)) {
        state.unauthorized.deferreds = _.tail(state.unauthorized.deferreds)
      }
    },
    processedUnauthorizedRequest (state) {
      state.unauthorized.inProgress = false
      state.unauthorized.confirmed = false
      state.unauthorized.deferreds = []
    }
  },
  actions: {
    ping ({ commit, state }) {
      if (typeof window.navigator.onLine === 'undefined' || window.navigator.onLine) {
        // assume online if browser doesn't support it
        if (!state.status.clientOnline) {
          commit('clientOnline')
        }
        return AuthService.ping().then(
          pong => {
            if (!state.status.serverOnline) {
              commit('serverOnline')
            }
            if (typeof pong.user !== 'undefined') {
              console.log('Sucessful login')
              commit('loginSuccess', pong.user)
              CalcService.definitions().then(
                response => {
                  commit('calc/definitions', response, { root: true })
                  console.log('definitions committed in store')
                })
              while (state.unauthorized.confirmed && !_.isEmpty(state.unauthorized.deferreds)) {
                const deferred = _.head(state.unauthorized.deferreds)
                AuthService.replay(deferred.config).then(
                  response => {
                    deferred.resolve(response)
                  },
                  error => {
                    deferred.reject(error)
                  }
                )
                commit('processedDeferredUnauthorizedRequest')
              }
              commit('processedUnauthorizedRequest')
            } else {
              if (state.status.loggedIn || state.user) {
                commit('logoutSuccess')
              }
            }
            return Promise.resolve(pong.user)
          },
          () => {
            if (state.status.serverOnline) {
              commit('serverOffline')
            }
            return Promise.resolve()
          }
        )
      } else {
        if (state.status.clientOnline) {
          commit('clientOffline')
        }
        return Promise.reject(Error('client offline'))
      }
    },
    createUnauthorizedRequest ({ commit }, config) {
      const deferred = {
        config: config
      }
      const p = new Promise((resolve, reject) => {
        deferred.resolve = resolve
        deferred.reject = reject
      })
      commit('unauthorizedRequest', deferred)
      return p
    },
    confirmUnauthorizedRequest ({ commit }, handleLogin) {
      commit('confirmedUnauthorizedRequest')
      if (handleLogin === undefined) {
        AuthService.defaultLogin()
      } else {
        handleLogin()
      }
    },
    declineUnauthorizedRequest ({ commit, state }) {
      while (!_.isEmpty(state.unauthorized.deferreds)) {
        const deferred = _.head(state.unauthorized.deferreds)
        deferred.reject()
        commit('processedDeferredUnauthorizedRequest')
      }
      commit('declinedUnauthorizedRequest')
    }
  }
}
