<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-1 col-xl-1 ak-navbar">
        <TheBaReSuDeButtons class="mt-3"
                            @back="showList"
                            :show-back="true"
                            :show-submit="false"
                            :show-pdf="false"
                            :show-delete="false"
        />
      </div>
      <div class="col-12 col-md-10 col-xl-10">
        <h1>{{ $t("headingAdminCalclist") }}</h1>

          <div>
              <b-container fluid>
                <!-- User Interface controls -->
                <b-row>
                  <b-col lg="6" class="my-1">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                :placeholder="$t('typeToSearch.label')"
              ></b-form-input>
                  </b-col>
              <b-col lg="6" class="my-1">
                <b-form-group
                  v-model="sortDirection"
                  :label="$t('filter.label')"
                  :description="$t('filter.hint')"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-checkbox-group
                    v-model="filterOn"
                    :aria-describedby="ariaDescribedby"
                    class="mt-1"
                  >
                    <b-form-checkbox value="title">{{ $t('title.label') }}</b-form-checkbox>
                    <b-form-checkbox value="calculationNumber">{{ $t('calculationNumber.label') }}</b-form-checkbox>
                    <b-form-checkbox value="clientCountryEU">EU</b-form-checkbox>
                    <b-form-checkbox value="owner">{{ $t('owner.label') }}</b-form-checkbox>
                    <b-form-checkbox value="projectLeader">{{ $t('projectLeader.label') }}</b-form-checkbox>
                    <b-form-checkbox value="client">{{$t('client.label')}}</b-form-checkbox>
                    <b-form-checkbox value="startDate">{{$t('startDate.label')}}</b-form-checkbox>
                    <b-form-checkbox value="endDate">{{$t('endDate.label')}}</b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>
              </b-col>
                </b-row>
              </b-container>
            <b-table class="mb-2 mt-2" small striped hover show-empty :empty-text="$t('tableEmpty.label')" :items="calcs"
                     :fields="tableFields" :filter="filter" :filter-included-fields="filterOn"
                     :empty-filtered-text="$t('filter.tableEmpty.label')"
                     sort-icon-left>
              <template #cell(show)="data">
                <b-button size="sm" @click="show(data.item)" title="Anzeigen" class="mr-1"><b-icon-search/></b-button>
              </template>
            </b-table>
            </div>
            </div>
        </div>
      </div>
</template>

<script>
import utilMixin from '@/mixins/util.mixin'
import { dateMixin } from '@/mixins/date.mixin'
import { authMixin } from '@/mixins/auth.mixin'
import CalcService from '@/services/calc.service'
import TheBaReSuDeButtons from '@/components/TheBaReSuDeButtons'

import _ from 'lodash'

export default {
  name: 'AdminCalculationsTable',
  components: { TheBaReSuDeButtons },
  mixins: [utilMixin, dateMixin, authMixin],
  data () {
    return {
      calcData: { },
      calcs: [],
      cnt: 0,
      filter: null,
      filterOn: ['title', 'calculationNumber', 'clientCountryEU', 'owner', 'projectLeader', 'client', 'startDate', 'endDate'],
      sortDirection: 'asc',
      tableFields: [
        { key: 'show', label: '' },
        {
          key: 'title',
          label: this.$t('title.label'),
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          formatter: value => {
            return value || '(' + this.$t('missing.text') + ')'
          }
        },
        {
          key: 'calculationNumber',
          label: this.$t('calculationNumber.label'),
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          formatter: value => {
            return value || '(' + this.$t('missing.text') + ')'
          }
        },
        {
          key: 'clientCountryEU',
          label: 'EU',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          formatter: value => {
            if (value) { return 'Ja' }
            return 'Nein'
          }
        },
        {
          key: 'owner',
          label: this.$t('owner.label'),
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          formatter: (value, key, item) => {
            const owners = _.map(_.filter(item.acls, { right: 'owner' }), 'name')
            return owners.join(', ')
          }
        },
        {
          key: 'projectLeader',
          label: this.$t('projectLeader.label'),
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          formatter: value => {
            return value || '(' + this.$t('missing.text') + ')'
          }
        },
        {
          key: 'client',
          label: this.$t('client.label'),
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          formatter: value => {
            return value || '(' + this.$t('missing.text') + ')'
          }
        },
        {
          key: 'startDate',
          label: this.$t('startDate.labelShort'),
          sortable: true,
          filterByFormatted: true,
          formatter: value => { return this.formatDateDay(value) }
        },
        {
          key: 'endDate',
          label: this.$t('endDate.labelShort'),
          sortable: true,
          filterByFormatted: true,
          formatter: value => { return this.formatDateDay(value) }
        }
      ]
    }
  },
  created () {
    this.reset()
  },
  mounted: function () {
    document.getElementById('filter-input').focus()
  },
  methods: {
    reset () {
      CalcService.adminListCalcs().then(
        response => {
          this.calcs = response.items
          this.cnt = response.cnt
        }
      ).catch(
        error => {
          this.makeToast(this.$i18n.t('error.text', { status: error.status, message: error.message, id: 'list' }), this.$i18n.t('result.error.title'), 'danger')
        }
      )
    },
    onFiltered (filteredItems) {
      console.log('filtered: ', filteredItems)
    },
    owners (acls) {
      const owners = _.map(_.filter(acls, { right: 'owner' }), 'name')
      return owners.join(', ')
    },
    show (item) {
      // this.$emit('show', item)
      this.$router.push({ name: 'calculation', params: { id: item.uuid } })
    },
    showList () {
      this.$router.push({ name: 'list' })
    },
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    }
  }
}
</script>

<style scoped>

</style>
