import _ from 'lodash'
import store from '@/store'
import EmpcostService from '@/services/empcost.service'

class UtilService {
  uuidv4 () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0; const v = c === 'x' ? r : (r & 0x3 | 0x8)
      return v.toString(16)
    })
  }

  formatEuro (val) {
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(val)
  }

  updateValuesForNewDate (startDateStr, endDateStr) {
    const startDate = new Date(startDateStr)
    const endDate = new Date(endDateStr)
    // overheadSurcharges
    const matchingOSCs = _.find(store.state.calc.definitions.overheadsurcharges2 || [], d =>
      new Date(d.validFrom) <= startDate && new Date(d.validTo) > startDate
    ).values
    store.commit('calc/overheadsurcharges', matchingOSCs)

    // materialOverheadsurcharge
    const matchingMOSC = _.find(store.state.calc.definitions.materialOverheadsurcharges, d =>
      new Date(d.validFrom) <= startDate && new Date(d.validTo) > startDate
    ).value
    store.commit('calc/materialOverheadsurcharge', matchingMOSC)

    // annualIncrease
    const matchingAnnualIncrease = _.find(store.state.calc.definitions.annualIncrease, d =>
      new Date(d.validFrom) <= startDate && new Date(d.validTo) > startDate
    ).value
    store.commit('calc/annualIncrease', matchingAnnualIncrease)

    const matchingAnnualIncreaseSpecificValues = _.find(store.state.calc.definitions.annualIncrease, d =>
      new Date(d.validFrom) <= startDate && new Date(d.validTo) > startDate
    ).specificValues
    if (matchingAnnualIncreaseSpecificValues !== undefined) {
      store.commit('calc/annualIncreaseSpecificValues', matchingAnnualIncreaseSpecificValues)
    }

    // Hilfskräfte
    const matchingHKSalaries = _.find(store.state.calc.definitions.hkSalaries, d =>
      new Date(d.validFrom) <= startDate && new Date(d.validTo) > startDate
    )
    store.commit('calc/hkSalary', matchingHKSalaries)

    // Staff
    const matchingStaffSalaries = _.find(store.state.calc.definitions.staffSalaries, d =>
      new Date(d.validFrom) <= startDate && new Date(d.validTo) > startDate
    )
    store.commit('calc/staffSalary', matchingStaffSalaries)

    // payriseFactor Hilfskräfte
    const hkPrf = EmpcostService.payRiseFactor(matchingHKSalaries.baseYear, startDate, endDate, matchingAnnualIncrease, matchingAnnualIncreaseSpecificValues)
    store.commit('calc/hkPrf', hkPrf)

    // payriseFactor Staff
    const staffPrf = EmpcostService.payRiseFactor(matchingStaffSalaries.baseYear, startDate, endDate, matchingAnnualIncrease, matchingAnnualIncreaseSpecificValues)
    store.commit('calc/staffPrf', staffPrf)
  }
}

export default new UtilService()
