import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import axios from 'axios'
// import VueCurrencyInput from 'vue-currency-input'
import VueCompositionAPI from '@vue/composition-api'

Vue.use(VueCompositionAPI)
Vue.config.productionTip = false

axios.interceptors.response.use(
  // Return a successful response back to the calling service
  response => {
    if (!store.state.auth.status.serverOnline) {
      store.commit('auth/serverOnline')
    }
    return response
  },
  // Return any error which is not due to authentication back to the calling service
  error => {
    if (error.response) {
      if (error.response.status !== 401) {
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    } else {
      if (store.state.auth.status.serverOnline) {
        store.commit('auth/serverOffline')
      }
      return new Promise((resolve, reject) => {
        reject(error)
      })
    }
    return store.dispatch('auth/createUnauthorizedRequest', error.config)
  }
)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

// Vue.use(VueCurrencyInput, { globalOptions: { locale: 'de-DE', currency: 'EUR', allowNegative: false } })
