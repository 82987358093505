import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Playground from '@/views/Playground'
import store from '@/store/index'
import Imprint from '../views/Imprint'
import Help from '../views/Help'
import Privacy from '@/views/Privacy'
import Accessibility from '@/views/Accessibility'
import CalculationsTable from '@/components/calculation/CalculationsTable'
import AdminCalculationsTable from '@/components/calculation/AdminCalculationsTable'
import CalculationAcls from '@/components/calculation/CalculationAcls.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/list',
    name: 'list',
    component: CalculationsTable
  },
  {
    path: '/adminlist',
    name: 'adminlist',
    component: AdminCalculationsTable
  },
  {
    path: '/acls/:id',
    name: 'acls',
    props: route => ({
      id: route.params.id
    }),
    component: CalculationAcls
  },
  {
    path: '/calculation/id/:id',
    name: 'calculation',
    props: route => ({
      id: route.params.id
    }),
    // props: route => ({ id: route.params.id, ro: Number(route.params.ro) }),
    component: () => import(/* webpackChunkName: "calculation" */ '../views/Calculation.vue')
  },
  {
    path: '/calculation/clone/:cloneof',
    name: 'clonecalculation',
    props: route => ({
      cloneof: route.params.cloneof
    }),
    // props: route => ({ id: route.params.id, ro: Number(route.params.ro) }),
    component: () => import(/* webpackChunkName: "calculation" */ '../views/Calculation.vue')
  },
  {
    path: '/calculation/new',
    name: 'newcalculation',
    // props: route => ({ id: route.params.id, ro: Number(route.params.ro) }),
    component: () => import(/* webpackChunkName: "calculation" */ '../views/Calculation.vue')
  },
  {
    path: '/playground',
    name: 'playground',
    component: Playground
  },
  {
    path: '/accessibility',
    name: 'accessibility',
    component: Accessibility
  },
  {
    path: '/imprint',
    name: 'imprint',
    component: Imprint
  },
  {
    path: '/help',
    name: 'help',
    component: Help
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const publicPages = ['/', '/help', '/imprint', '/privacy', '/accessibility']
  const authRequired = !publicPages.includes(to.path)
  if (!store.state.auth.user) {
    await store.dispatch('auth/ping')
  }
  const loggedIn = store.state.auth.user
  if (loggedIn) {
    await store.dispatch('calc/loadPreferences')
  }
  if (authRequired && !loggedIn) {
    next('/')
  } else {
    next()
  }
})

export default router
