<template>
  <div class="home mx-3">
    <h1>Hilfe</h1>
    <ul>
        <li>Bei inhaltlichen Fragen wenden Sie sich bitte an das Referat H3 - Drittmittel
      und Rechtsangelegenheiten der Forschung
      (<a href="https://www.intern.fau.de/haushalt-und-finanzen/drittmittel-und-eu-angelegenheiten/">https://www.intern.fau.de/haushalt-und-finanzen/drittmittel-und-eu-angelegenheiten/</a>)
      oder S-OUTREACH – Outreach Support (<a href="https://www.fau.de/outreach/">https://www.fau.de/outreach/</a>)</li>
        <li>
          Bei technischen Problemen wenden Sie sich bitte per E-Mail an:
          <a href="mailto:rrze-ak-admins@fau.de">rrze-ak-admins@fau.de</a>
        </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Help',
  mounted: function () {
    this.scrollToTop()
  },
  methods: {
    scrollToTop () {
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style lang="scss">
.home {
  min-height: calc(100vh - 161px);
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

</style>
