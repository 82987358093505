<template>
  <div>
    <b-container fluid>
      <b-card-text><b-alert show><b-icon-info-circle/> <p v-html="$t('researchBonus.hint.text')"></p></b-alert></b-card-text>
      <b-row>
        <b-col sm="4">
          <label for="research-bonus">{{ $t("researchBonus.label") }}</label>
        </b-col>
        <b-col sm="8">
          <b-form-input :disabled="calcdata.researchType!=='contractresearch'"  class="mb-2" id="research-bonus" v-model.number="calcdata.researchBonus" type="number" min="0" lang="de"></b-form-input>
        </b-col>
      </b-row>
      <b-button size="sm" variant="success" @click="doSave">{{ $t("button.save.label") }}</b-button>
    </b-container>
  </div>
</template>

<script>
import { CurrencyDirective } from 'vue-currency-input'
export default {
  name: 'TheResearchBonus',
  directives: {
    currency: CurrencyDirective
  },
  props: {
    calcdata: {
      type: Object,
      required: true
    }
  },
  methods: {
    doSave () {
    }
  }
}
</script>

<style scoped>

</style>
