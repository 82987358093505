<template>
  <footer id="layout-footer" class="row m-0 px-0 py-2 main-footer">
    <div class="container">
      <div class="footer-row">
        <div class="footer-logo">
          <a href="http://fau.de" target="_blank">
            <b-img  src="../assets/icons/footer/fau2022_blue.svg" alt="FAU" class="faulogo" height="65px" />
          </a>
        </div>
        <div class="footer-address">
        </div>
        <div class="footer-meta">
          <nav aria-label="Kontakt, Impressum und Zusatzinformationen">
          <ul id="footer-nav" class="menu">
            <li v-for="elem in links" :key="elem.href" class="nav-item">
              <router-link :to="elem.href" >{{ elem.name }}</router-link>
            </li>
          </ul>
          </nav>
          <nav class="svg-socialmedia round hoverbg" aria-label="RRZE Links">
            <div>
              <ul>
                <li class="fau-rrze">
                  <a href="http://rrze.fau.de" ><b-img src="../assets/icons/footer/rrze.svg" alt="RRZE" height="36" /></a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'TheFooter',
  data () {
    return {
    }
  },
  computed: {
    links () {
      return [
        { href: '/help', name: this.$t('footer.help.label') },
        { href: '/imprint', name: this.$t('footer.imprint.label') },
        { href: '/privacy', name: this.$t('footer.privacy.label') },
        { href: '/accessibility', name: this.$t('footer.accessibility.label') }
      ]
    }
  }
}
</script>

<style scoped>

</style>
