<template>
  <div class="row" if="calcdata">
    <div class="col-12 col-md-3 col-xl-3 ak-navbar">
      <TheBaReSuDeButtons
        :show-delete="false"
        :show-reset="isOwner && aclsChanged"
        :show-submit="isOwner && aclsChanged"
        :show-pdf="false"
        @back="showLeaveConfirm"
        @reset="onReset"
        @submit="saveCalculationAcls"
      />
    </div>
    <div class="col-12 col-md-9 col-xl-9">
      <h2 v-if="calcdata.title">{{ calcdata.title }}</h2>
      <h2 class="font-italic text-secondary" v-else>{{ $t('missing.text') }}</h2>
      <b-alert show dismissible variant="info">
        <b-list-group>
          <b-list-group-item>{{ $t('acl.owner.text') }}</b-list-group-item>
          <b-list-group-item>{{ $t('acl.readwrite.text') }}</b-list-group-item>
          <b-list-group-item>{{ $t('acl.readonly.text') }}</b-list-group-item>
        </b-list-group>
      </b-alert>
      <h6>{{ $t('acl.userselect.text') }}</h6>
      <CalculationUserSelect v-model="newAcl" :ou="ou" v-if="isOwner"/>
      <h6 class="mt-5">{{ $t('acl.userlist.text') }}</h6>
      <b-list-group class="mt-2">
        <b-list-group-item v-for="(acl, idx) in form.acls" :key="idx">
          <div class="row">
            <div class="col-12 col-md-6">{{ acl.name }} <span class="text-secondary">({{ acl.mail }})</span></div>
            <div class="col-12 col-md-6 text-md-right">
              <b-form-group class="mb-0">
                <b-form-radio-group
                  v-if="isOwner"
                  v-model="acl.right"
                  :options="rightOptions(acl)"
                  name="radios-btn-default"
                  button-variant="outline-secondary"
                  size="sm"
                  class="mr-2 mb-1"
                  buttons
                />
                <span v-else class="mb-1">{{ rightText(acl) }}</span>
                <b-button @click="revoke(idx)" size="sm" variant="danger" :disabled="isRevokeDisabled(acl)" v-if="isOwner" class="mb-1">{{ $t('revoke.label') }}</b-button>
              </b-form-group>
            </div>
          </div>
        </b-list-group-item>
      </b-list-group>
    </div>
  </div>
</template>

<script>
import TheBaReSuDeButtons from '@/components/TheBaReSuDeButtons'
import { authMixin } from '@/mixins/auth.mixin'
import utilMixin from '@/mixins/util.mixin'
import _ from 'lodash'
import CalculationUserSelect from '@/components/calculation/CalculationUserSelect'
import * as jsonpatch from 'fast-json-patch'
import CalcService from '@/services/calc.service'

export default {
  name: 'CalculationAcls',
  components: { CalculationUserSelect, TheBaReSuDeButtons },
  mixins: [authMixin, utilMixin],
  props: {
    id: {
      type: String,
      require: true
    }
  },
  // props: {
  //   calcdata: {
  //     type: Object,
  //     required: true
  //   }
  // },
  data () {
    return {
      calcdata: { },
      form: { },
      newAcl: null,
      savedAcls: null
    }
  },
  created () {
    console.log('CalculationAcls -> created: ' + JSON.stringify(this.$route.params))
    if (this.$route.params.id) {
      CalcService.get(this.$route.params.id).then(
        response => {
          this.calcdata = _.cloneDeep(response)
          this.form = {
            uuid: this.calcdata.uuid,
            acls: _.cloneDeep(this.calcdata.acls)
          }
        }
      ).catch(
        error => {
          this.makeToast(this.$i18n.t('error.text', {
            status: error.status,
            message: error.message,
            id: 'acls'
          }), this.$i18n.t('result.error.title'), 'danger')
        }
      )
    }
  },
  computed: {
    aclsChanged () {
      if (this.calcdata.acls && this.form.acls) {
        return jsonpatch.compare(this.calcdata.acls, this.form.acls).length > 0
      } else {
        return false
      }
    },
    isOwner () {
      if (_.has(this.calcdata, 'acls')) {
        if (_.find(this.calcdata.acls, acl => acl.id === this.currentUser.username && acl.right === 'owner')) {
          return true
        }
      }
      return false
    },
    ou () {
      if (_.has(this.calcdata, 'organization.id')) {
        return this.calcdata.organization.id
      }
      return null
    },
    singleOwner () {
      const ownerCnt = _.filter(this.form.acls, { right: 'owner' }).length
      return ownerCnt < 2
    },
    editableAcls () {
      return _.filter(this.form.acls, share => {
        // if (share.id === this.item.owner) {
        //   return false
        // }
        return true
      })
    }
  },
  watch: {
    newAcl (newValue) {
      if (newValue) {
        const newAcl = { id: newValue.id, name: newValue.name, mail: newValue.mail, right: 'write' }
        if (newValue.ou) {
          newAcl.name += ' (' + newValue.ou + ')'
        }
        let alreadyAcl = false
        this.form.acls.forEach(acl => {
          if (acl.id === newAcl.id) {
            alreadyAcl = true
          }
        })
        if (!alreadyAcl) {
          this.form.acls.push(newAcl)
        }
        this.newAcl = null
      }
    }
    // calcdata: {
    //   immediate: true,
    //   deep: true,
    //   handler: 'reset'
    // }
  },
  methods: {
    revoke (idx) {
      console.log('revoking: ' + idx)
      this.form.acls.splice(idx, 1)
    },
    rightText (acl) {
      if (acl.right === 'owner') {
        return this.$t('owner.label')
      } else if (acl.right === 'write') {
        return this.$t('readwrite.label')
      } else if (acl.right === 'read') {
        return this.$t('readonly.label')
      }
      return this.$t('unknown.label')
    },
    rightOptions (acl) {
      const options = []
      const owner = { text: this.$t('owner.label'), value: 'owner' }
      const write = { text: this.$t('readwrite.label'), value: 'write' }
      const read = { text: this.$t('readonly.label'), value: 'read' }
      options.push(owner)
      if (acl.right === 'owner' && this.singleOwner) {
        write.disabled = true
        read.disabled = true
      }
      options.push(write)
      options.push(read)
      return options
    },
    isRevokeDisabled (acl) {
      return acl.right === 'owner' && this.singleOwner
    },
    saveCalculationAcls () {
      // console.log(JSON.stringify(item))
      CalcService.updateAcls(this.form).then(
        response => {
          this.makeToast(this.$i18n.t('updated.text', {
            id: 'Kalkulation',
            code: response.code
          }), this.$i18n.t('result.success.title'), 'success')
          // this.closeCalculationAcls()
          //          this.currentAcls.acls = item.acls
        }
      ).catch(
        error => this.makeToast(this.$i18n.t('error.text', {
          id: this.calcdata.uuid,
          status: error.status,
          message: error.message
        }), this.$i18n.t('result.error.title'), 'danger')
      )
    },
    onReset () {
      this.reset(this.calcdata)
    },
    reset (calcdata) {
      const form = {
        uuid: calcdata.uuid
      }
      if (_.has(calcdata, 'acls')) {
        form.acls = _.cloneDeep(calcdata.acls)
      } else {
        form.acls = []
      }
      this.form = form
    },
    showLeaveConfirm () {
      if (this.aclsChanged) {
        this.$bvModal.msgBoxConfirm(this.$t('leaveunsaved.confirm.text'), this.confirmBoxOptions())
          .then(value => {
            if (value) {
              this.$router.push('/list')
            }
          })
      } else {
        this.$router.push('/list')
      }
    },
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    }
  }
}
</script>

<style scoped>

</style>
