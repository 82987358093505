<template>
  <div class="row">
    <div class="col-12 mt-1" v-if="showAdminlist">
      <b-button @click="$emit('adminlist')" variant="secondary" class="mr-1 w-100 text-left">
        <b-icon-files shift-v="-2" class="mr-1"/>
        {{ myLabels.adminlist }}
      </b-button>
    </div>
    <div class="col-12 mt-1" v-if="showBack">
      <b-button @click="$emit('back')" v-if="showBack" :disabled="disableBack" variant="secondary" class="mr-1 w-100 text-left">
        <b-icon-files shift-v="-2" class="mr-1"/>
        {{ myLabels.back }}
      </b-button>
      </div>
    <div class="col-12 pt-2" v-if="showReset">
      <b-button @click="$emit('reset')" v-if="showReset" :disabled="disableReset" variant="warning" class="mr-1 w-100 text-left">
        <b-icon-arrow-counterclockwise shift-v="-1" class="mr-1"/>
        {{ myLabels.reset }}
      </b-button>
    </div>
    <div class="col-12 pt-2" v-if="showSubmit">
      <b-button @click="$emit('submit')" :disabled="disableSubmit" variant="primary" class="mr-1 w-100 text-left">
        <b-icon-file-earmark-arrow-down shift-v="-1" class="mr-1"/>
        {{ myLabels.submit }}
      </b-button>
    </div>
    <div class="col-12 pt-2" v-if="showPdf">
      <b-button variant="primary" class="mr-1 w-100 text-left" @click="$emit('pdf')">
        <b-icon-stickies shift-v="-1" class="mr-1"/>
        {{ myLabels.pdf }}
      </b-button>
    </div>
    <div class="col-12 pt-2" v-if="showCreateNumber">
      <b-button variant="primary" class="mr-1 w-100 text-left" @click="$emit('createNumber')">
        <b-icon-sort-numeric-down shift-v="-1" class="mr-1"/>
        {{ myLabels.createNumber }}
      </b-button>
    </div>
    <div class="col-12 pt-2" v-if="showRelease">
      <b-button @click="$emit('release')" :disabled="disableRelease" variant="success" class="mr-1 w-100 text-left">
        <b-icon-cloud-arrow-up shift-v="-1" class="mr-1"/>
        {{ myLabels.release }}
      </b-button>
      <b-form-checkbox :checked="validate" @change="$emit('update:validate', $event)" switch>{{ myLabels.validate }}</b-form-checkbox>
    </div>
    <div class="col-12 pt-2" v-if="showDelete">
      <b-button @click="$emit('delete')" :disabled="disableDelete" variant="danger" class="mr-1 w-100 text-left">
        <b-icon-trash shift-v="-1" class="mr-1"/>
        {{ myLabels.delete }}
      </b-button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TheBaReSuDeButtons',
  props: {
    showBack: {
      type: Boolean,
      default: true
    },
    disableBack: {
      type: Boolean,
      default: false
    },
    showAdminlist: {
      type: Boolean,
      default: false
    },
    showReset: {
      type: Boolean,
      default: false
    },
    disableReset: {
      type: Boolean,
      default: false
    },
    showSubmit: {
      type: Boolean,
      default: true
    },
    disableSubmit: {
      type: Boolean,
      default: false
    },
    showValidate: {
      type: Boolean,
      default: false
    },
    disableValidate: {
      type: Boolean,
      default: false
    },
    validate: {
      type: Boolean,
      default: false
    },
    showRelease: {
      type: Boolean,
      default: false
    },
    disableRelease: {
      type: Boolean,
      default: false
    },
    showDelete: {
      type: Boolean,
      default: true
    },
    showPdf: {
      type: Boolean,
      default: true
    },
    disablePdf: {
      type: Boolean,
      default: true
    },
    showCreateNumber: {
      type: Boolean,
      default: false
    },
    disableDelete: {
      type: Boolean,
      default: false
    },
    labels: {
      type: Object,
      default: () => { return {} }
    }
  },
  computed: {
    myLabels () {
      const defaultLabels = {
        back: this.$t('back.label'),
        adminlist: this.$t('adminlist.label'),
        reset: this.$t('reset.label'),
        submit: this.$t('submit.label'),
        release: this.$t('release.label'),
        validate: this.$t('validate.label'),
        delete: this.$t('delete.label'),
        pdf: this.$t('pdfdownload.label'),
        createNumber: this.$t('createNumber.label'),
        archive: this.$t('archive.label'),
        reactivate: this.$t('reactivate.label')
      }
      return Object.assign({ ...defaultLabels }, this.labels)
    }
  }
}
</script>

<style scoped>

</style>
