<template>
  <div>
    <b-card>
      <template #header>
        <h6>{{ $t('headingStaff' + capitalize(paymentType)) }}</h6>
      </template>
      <template>
        <b-alert :show="alertData.text != null" :variant="alertData.variant">{{ alertData.text }}</b-alert>
        <b-card>
          <b-container fluid>
            <b-row class="my-1" v-for="(value, name) in employeeDefs" :key="name">
              <b-col sm="4">
                <label :for="`field-${id}-${name}`">{{ $t(`employee.${name}.label`) }}{{ value.required?'*': ''}}</label>
              </b-col>
              <b-col sm="8">
                <b-form-select v-if="value.type === 'select'" :id="`field-${id}-${name}`" v-model="employeeTemplate[name]" :options="value.options"  :state="fieldState(name)"></b-form-select>
                <b-form-input v-else-if="value.type === 'number'" :id="`field-${id}-${name}`" :type="value.type" v-model="employeeTemplate[name]" lang="de" step="any" :state="fieldState(name)" ></b-form-input>
                <b-form-input v-else :id="`field-${id}-${name}`" :type="value.type" v-model="employeeTemplate[name]" :state="fieldState(name)"></b-form-input>
                <b-form-invalid-feedback :id="`field-${id}-${name}-input-live-feedback`">
                  {{ $t(`employee.${name}.error`) }}
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
<!--            Actions -->
            <b-row>
              <b-col sm="4"></b-col>
              <b-col sm="8">
                <span v-if="edit">
                  <b-button size="sm" variant="success" @click="updateEmployee(employeeTemplate.uuid)">{{ $t('button.save.label') }}</b-button>
                  <b-button size="sm" class="ml-2" variant="danger" @click="cancelEdit">{{ $t('button.cancel.label') }}</b-button>
                </span>
                <span v-else>
                  <b-button size="sm" variant="success" @click="addEmployee">{{ $t('button.add.label') }}</b-button>
                </span>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
<!--        Table of Staff Employees-->
        <b-card v-if="myEmployees.length" class="mt-2">
          <b-table class="mb-2 mt-2" small striped hover :items="myEmployees" :fields="staffFields">
            <template #cell(actions)="data">
              <span v-if="!edit || data.item.uuid !== employeeTemplate.uuid">
                <b-button :disabled="edit" @click="editEmployee(data.item.uuid)" variant="info" size="sm">
                  <b-icon-pencil />
                </b-button>
                <b-button :disabled="edit" class="ml-2" @click="removeEmployee(data.item.uuid)" variant="danger" size="sm">
                  <b-icon-trash />
                </b-button>
              </span>
              <span v-else>{{$t('inEditMode.label')}}
                <b-button disabled variant="secondary" size="sm">
                  <b-icon-lock />
                </b-button>
              </span>
            </template>
            <!--            Sums Footer -->
            <template v-slot:custom-foot>
              <TheEmployeeTableSumsFooter :totalCost="totalCost" :overheadSurcharge="calcdata.overheadSurcharge" :totalOverheadSurcharge="totalOverheadSurcharge" :totalSumOverheadSurcharge="totalSumOverheadSurcharge"/>
            </template>
          </b-table>
        </b-card>
      </template>

    </b-card>
  </div>
</template>

<script>
import _ from 'lodash'
import EmpcostService from '@/services/empcost.service'
import UtilService from '@/services/util.service'
import utilMixin from '@/mixins/util.mixin'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import TheEmployeeTableSumsFooter from '@/components/TheEmployeeTableSumsFooter'

export default {
  name: 'TheStaffEmployee',
  components: { TheEmployeeTableSumsFooter },
  mixins: [utilMixin],
  setup () {
    return { v$: useVuelidate() }
  },
  props: {
    calcdata: {
      type: Object,
      required: true
    },
    paymentType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      id: null,
      edit: false,
      alertData: {
        variant: 'info',
        text: null
      },
      employeeTemplate: this.freshStaffTemplate(),
      employeeDefs: {
        payGrade: { type: 'select', options: Object.keys(this.$store.state.calc.staffSalary.values) },
        percentage: { type: 'number', lang: 'de' },
        months: { type: 'number', lang: 'de' },
        description: { type: 'text', required: true }
      }
    }
  },
  validations () {
    const gtZero = (value) => value != null && Number(value) > 0
    return {
      employeeTemplate: {
        description: { required },
        months: { required, gtZero },
        percentage: { required, gtZero }
      }
    }
  },
  computed: {
    totalCost () {
      return _.sum(_.map(this.myEmployees, (employee) => this.empCost(employee)))
    },
    totalOverheadSurcharge () {
      if (this.calcdata.overheadSurcharge == null) {
        return null
      }
      return (this.totalCost + Number.EPSILON) * Number(this.calcdata.overheadSurcharge) / 100
    },
    totalSumOverheadSurcharge () {
      return Number(this.totalCost + this.totalOverheadSurcharge)
    },
    myEmployees () {
      return _.filter(this.calcdata.employees, (emp) => {
        return emp.employmentType === 'staff' && emp.paymentType === this.paymentType
      })
    }
  },
  mounted () {
    this.id = this._uid
  },
  methods: {
    fieldState (field) {
      if (this.v$.employeeTemplate[field] == null || !this.v$.employeeTemplate[field].$dirty) {
        return null
      }
      return !this.v$.employeeTemplate[field].$error
    },
    addEmployee () {
      this.v$.$touch()
      if (this.v$.$error) return
      this.calcdata.employees.push(this.employeeTemplate)
      this.alertData.text = this.$t('entry.saved.label')
      this.resetEmployee()
    },
    editEmployee (uuid) {
      const emp = _.find(this.calcdata.employees, o => o.uuid === uuid)
      this.edit = true
      this.employeeTemplate.payGrade = emp.payGrade
      this.employeeTemplate.percentage = emp.percentage
      this.employeeTemplate.description = emp.description
      this.employeeTemplate.months = emp.months
      this.employeeTemplate.uuid = uuid
      this.alertData = { text: this.$t('entry.editHint.label'), variant: 'info' }
    },
    updateEmployee (uuid) {
      this.v$.$touch()
      if (this.v$.$error) return
      const emp = _.find(this.calcdata.employees, o => o.uuid === uuid)
      this.edit = false
      emp.payGrade = this.employeeTemplate.payGrade
      emp.percentage = this.employeeTemplate.percentage
      emp.description = this.employeeTemplate.description
      emp.months = this.employeeTemplate.months
      this.alertData = { text: this.$t('entry.saved.label'), variant: 'info' }
      this.resetEmployee()
    },
    removeEmployee (uuid) {
      this.$bvModal.msgBoxConfirm(this.$t('deleteConfirm.message'), this.confirmBoxOptions())
        .then(value => {
          if (value === true) {
            const idx = _.findIndex(this.calcdata.employees, o => o.uuid === uuid)
            this.calcdata.employees.splice(idx, 1)
            this.alertData = { text: this.$t('entry.deleted.label'), variant: 'info' }
          } else {
            this.alertData.text = null
          }
        })
    },
    cancelEdit () {
      this.resetEmployee()
      this.edit = false
      this.alertData.text = null
    },
    resetEmployee () {
      this.employeeTemplate = this.freshStaffTemplate()
      this.v$.$reset()
    },
    freshStaffTemplate () {
      return {
        // payGrade: 'E13 Stufe 3-4',
        payGrade: EmpcostService.defaultStaff(),
        description: null,
        months: 12,
        percentage: 100,
        employmentType: 'staff',
        paymentType: this.paymentType,
        uuid: UtilService.uuidv4()
      }
    }
  }
}
</script>

<style scoped>

</style>
