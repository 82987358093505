<template>
  <div class="home mx-3">
    <h1>Impressum</h1>
    <h2>Herausgeber</h2>
    <ul class="listStyleTypeNone">
      <li>Friedrich-Alexander-Universität Erlangen-Nürnberg</li>
      <li>Schlossplatz 4, 91054 Erlangen</li>
      <li>Briefadresse: Postfach 3520, 91023 Erlangen</li>
      <li>Tel.: +49 (0)9131 85-0</li>
      <li>Fax: +49 (0)9131 85-22131</li>
      <li>E-Mail: <a href="mailto:poststelle@fau.de">poststelle@fau.de</a></li>
    </ul>
    <h2>Rechtsform und Vertretung</h2>
    <p>
    Die <a href="https://www.fau.de">Friedrich-Alexander-Universität Erlangen-Nürnberg (FAU)</a>
    ist eine Körperschaft des Öffentlichen Rechts und zugleich staatliche Einrichtung nach Art. 1 Abs. 1 BayHSchG.
    Sie wird gesetzlich vertreten durch den Präsidenten.
    </p>
    <p>
    Die inhaltliche Bereitstellung dieses Webangebots erfolgt durch: Zentrale Universitätsverwaltung.
    </p>
    <h2>Kontaktperson für den Inhalt</h2>
    <ul class="listStyleTypeNone">
      <li>Axel Klon</li>
      <li>Wöhrmühle 2</li>
      <li>91056 Erlangen</li>
      <li>E-Mail: <a href="mailto:axel.klon@fau.de">axel.klon@fau.de</a></li>
    </ul>
    <h2>Zuständige Aufsichtsbehörde</h2>
    <ul class="listStyleTypeNone">
      <li>Bayerisches Staatsministerium für Wissenschaft und Kunst</li>
      <li>Salvatorstraße 2</li>
      <li>80327 München</li>
      <li>Webseite: <a target="_blank" title="Externer Link: http://www.stmwfk.bayern.de/" href="https://www.stmwfk.bayern.de/">www.stmwfk.bayern.de</a></li>
    </ul>
    <h2 class="mt-4" id="identifikationsnummern">Identifikationsnummern</h2>
      <ul class="listStyleTypeNone">
        <li>Umsatzsteuer-Identifikationsnummer (gemäß § 27a Umsatzsteuergesetz): DE 132507686</li>
        <li>Steuernummer: 216/114/20045 (Finanzamt Erlangen)</li>
        <li>DUNS-Nummer: 327958716</li>
        <li>EORI-Nummer: DE4204891</li>
      </ul>
    <h3>Bankverbindung</h3>
    <ul class="listStyleTypeNone">
      <li>Bank: Bayerische Landesbank München</li>
      <li>Swift-Code (=BIC-Code): BYLA DE MM</li>
      <li>IBAN: DE66 7005 0000 0301 2792 80</li>
    </ul>
    <h2>Sicherheitsvorfälle</h2>
    <h4>Meldung über Missbrauch von Computer- und Netzwerk-Ressourcen</h4>
    <p>
      Sollte Ihnen irgendeine Form von Missbrauch der Computer- und Netzwerk-Ressourcen an der Friedrich-Alexander-Universität Erlangen-Nürnberg auffallen,
      <a href="https://www.rrze.fau.de/abuse/">informieren Sie bitte umgehend die für IT-Sicherheit</a> zuständige Stelle am RRZE.
    </p>
    <h2>Nutzungsbedingungen</h2>
    <p>
      Texte, Bilder, Grafiken sowie die Gestaltung dieser Internetseiten können dem Urheberrecht unterliegen.<br />
      Nicht urheberrechtlich geschützt sind nach § 5 des Urheberrechtsgesetz (UrhG)
    </p>
    <ul>
        <li>Gesetze, Verordnungen, amtliche Erlasse und Bekanntmachungen sowie Entscheidungen und amtlich verfasste Leitsätze zu Entscheidungen und </li>
        <li>andere amtliche Werke, die im amtlichen Interesse zur allgemeinen Kenntnisnahme veröffentlicht worden sind, mit der Einschränkung, dass die Bestimmungen über Änderungsverbot und Quellenangabe in § 62 Abs. 1 bis 3 und § 63 Abs. 1 und 2 UrhG entsprechend anzuwenden sind. </li>
      </ul>
    <p>
    Als Privatperson dürfen Sie urheberrechtlich geschütztes Material zum privaten und sonstigen eigenen Gebrauch im Rahmen
    des <a href="http://www.gesetze-im-internet.de/urhg/__53.html">§ 53 Urheberrechtsgesetz (UrhG)</a> verwenden. Eine Vervielfältigung oder Verwendung dieser Seiten oder Teilen davon
    in anderen elektronischen oder gedruckten Publikationen und deren Veröffentlichung ist nur mit unserer Einwilligung
    gestattet. Diese erteilen auf Anfrage die für den Inhalt Verantwortlichen. Der Nachdruck und die Auswertung
    von Pressemitteilungen und Reden sind mit Quellenangabe allgemein gestattet.
    </p>
    <p>
      Weiterhin können Texte, Bilder, Grafiken und sonstige Dateien ganz oder teilweise dem Urheberrecht Dritter unterliegen.
      Auch über das Bestehen möglicher Rechte Dritter geben Ihnen die für den Inhalt Verantwortlichen nähere Auskünfte,
      sofern diese Rechte nicht bereits durch entsprechende Hinweise im Webangebot kenntlich gemacht sind.
    </p>
    <h2>Hauftungsausschluss</h2>
    <p>
      Dieses Impressum gilt nur für den Webauftritt unter dieser Adresse.<br />
      Für die Inhalte anderer Webangebote sind die jeweiligen Einrichtungen verantwortlich.
    </p>
    <p>
      Alle auf dieser Internetseite bereitgestellten Informationen haben wir nach bestem Wissen und Gewissen erarbeitet und geprüft.
      Eine Gewähr für die jederzeitige Aktualität, Richtigkeit, Vollständigkeit und Verfügbarkeit der bereit gestellten
      Informationen können wir allerdings nicht übernehmen. Ein Vertragsverhältnis mit den Nutzern des Internetangebots
      kommt nicht zustande.
    </p>
    <p>
      Wir haften nicht für Schäden, die durch die Nutzung dieses Internetangebots entstehen. Dieser Haftungsausschluss gilt nicht,
      soweit die Vorschriften des  <a href="http://www.gesetze-im-internet.de/bgb/__839.html">§ 839 des Bürgerlichen Gesetzbuches</a>
      (Haftung bei Amtspflichtverletzung) einschlägig sind. Für etwaige Schäden, die beim Aufrufen oder Herunterladen von Daten durch Schadsoftware oder
      der Installation oder Nutzung von Software verursacht werden, wird nicht gehaftet.
    </p>
    <p>
      Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
      verantwortlich. Nach § 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
      gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
      Tätigkeit hinweisen.
    </p>
    <p>
      Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben
      hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
      Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
    </p>
    <p>
      Der Betreiber behält es sich ausdrücklich vor, einzelne Webseiten, Web-Dienste oder das gesamte Angebot ohne gesonderte
      Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.
    </p>
    <h2>Haftung für Links</h2>
    <p>Unser Angebot enthält Links zu externen Webauftritte Dritter, auf deren Inhalte wir keinen Einfluss haben.
      Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
      Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten  Seiten
      wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
      Zeitpunkt der Verlinkung nicht erkennbar.
      Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
      Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
    </p>
  </div>
</template>

<script>
export default {
  name: 'Imprint',
  mounted: function () {
    this.scrollToTop()
  },
  methods: {
    scrollToTop () {
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style lang="scss">
.home {
  min-height: calc(100vh - 161px);
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}
.listStyleTypeNone {
  list-style-type: none;
}

</style>
