<template>
  <b-navbar toggleable="lg" type="dark" variant="dark" fixed="top" :sticky="true" class="header">
    <b-navbar-brand to="/" title="app.description">{{ $t('app.title')}}</b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
<!--      <b-navbar-nav>
        <b-nav-item to="/calculation" v-if="showCalculationBoard" active-class="text-link">{{ $t('calculation')}}</b-nav-item>
      </b-navbar-nav>-->
    </b-collapse>
<!--    <b-button @click="ping" variant="outline"><b-icon-cloud title="Ping"/></b-button><span class="head-divider mb-1"> | </span>-->
<!--    <b-button @click="securedApi" variant="outline"><b-icon-server title="Secured API TEST"/></b-button><span class="head-divider mb-1"> | </span>-->
    <b-button v-if="currentUser" @click="logout" variant="outline"><b-icon-arrow-left :title="$t('signout.label')"/> {{ $t('signout.label') }}</b-button>
    <b-button v-else @click="login" variant="outline"><b-icon-box-arrow-in-right/> {{ $t('signin.label') }}</b-button>
<!--    <b-button v-if="!currentUser"  @click="onLocalLogin" variant="outline">&pi;</b-button>-->
  </b-navbar>
</template>

<script>
import AuthService from '@/services/auth.service'
import { authMixin } from '@/mixins/auth.mixin'

export default {
  name: 'TheHeader',
  props: {
    showlocallogin: {
      type: Boolean,
      required: false
    }
  },
  mixins: [authMixin],
  data () {
    return {
      langs: ['en', 'de'],
      nightMode: false
    }
  },
  computed: {
    showCalculationBoard () {
      return this.hasAnyRole(['ROLE_ADMIN'])
    }
  },
  watch: {
    nightMode (newValue) {
      if (newValue) {
        document.body.setAttribute('data-theme', 'night')
      } else {
        document.body.removeAttribute('data-theme')
      }
    }
  },
  methods: {
    securedApi () {
      AuthService.index().then(
        response => { console.log(response) },
        error => { console.log(error) }
      )
    },
    login () {
      AuthService.samlLogin()
    },
    logout () {
      AuthService.logout()
    },
    ping () {
      const requestStartedAt = new Date().getTime()
      this.$store.dispatch('auth/ping').then(
        () => this.makeToast(this.$i18n.t('ping.result.text', { time: new Date().getTime() - requestStartedAt }), this.$i18n.t('result.success.title'), 'success')
      ).catch(
        error => this.makeToast(this.$i18n.t('error.text', { status: 'na', message: error.message, id: 'ping' }), this.$i18n.t('result.error.title'), 'danger')
      )
    },
    onLocalLogin () {
      this.$emit('update:showlocallogin', true)
    },
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    }
  }
}
</script>

<style scoped>

</style>
