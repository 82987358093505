import CalcService from '@/services/calc.service'

export const calc = {
  namespaced: true,
  state: {
    preferredOu: null,
    gridPreferred: true,
    definitions: null,
    annualIncrease: null,
    annualIncreaseSpecificValues: null,
    materialOverheadsurcharge: null,
    overheadsurcharges: null,
    hkSalary: null,
    staffSalary: null,
    hkPrf: 1,
    staffPrf: 1
  },
  mutations: {
    preferredOu (state, preferredOu) {
      state.preferredOu = preferredOu
    },
    gridPreferred (state, gridPreferred) {
      state.gridPreferred = gridPreferred
    },
    toggleGridPreferred (state) {
      state.gridPreferred = !state.gridPreferred
    },
    definitions (state, definitions) {
      state.definitions = definitions
    },
    annualIncrease (state, annualIncrease) {
      state.annualIncrease = annualIncrease
    },
    annualIncreaseSpecificValues (state, annualIncreaseSpecificValues) {
      state.annualIncreaseSpecificValues = annualIncreaseSpecificValues
    },
    materialOverheadsurcharge (state, materialOverheadsurcharge) {
      state.materialOverheadsurcharge = materialOverheadsurcharge
    },
    overheadsurcharges (state, overheadsurcharges) {
      state.overheadsurcharges = overheadsurcharges
    },
    hkSalary (state, hkSalary) {
      state.hkSalary = hkSalary
    },
    staffSalary (state, staffSalary) {
      state.staffSalary = staffSalary
    },
    hkPrf (state, hkPrf) {
      state.hkPrf = hkPrf
    },
    staffPrf (state, staffPrf) {
      state.staffPrf = staffPrf
    }
  },
  actions: {
    setPreferredOu ({ commit, dispatch }, prefferedOu) {
      commit('preferredOu', prefferedOu)
      dispatch('savePreferences')
    },
    setGridPreferred ({ commit, dispatch }, gridPreferred) {
      commit('gridPreferred', gridPreferred)
      dispatch('savePreferences')
    },
    toggleAndSetGirdPreferred ({ commit, dispatch }) {
      commit('toggleGridPreferred')
      dispatch('savePreferences')
    },
    loadDefinitions ({ commit }) {
      return CalcService.definitions().then(
        response => {
          commit('definitions', response)
        }
      )
    },
    loadPreferences ({ commit }) {
      return CalcService.loadSettings().then(
        response => {
          commit('preferredOu', response.preferredOu)
          commit('gridPreferred', response.gridPreferred)
        }
      )
    },
    savePreferences ({ commit, state }) {
      return CalcService.saveSettings(state).then(
        response => {
          commit('preferredOu', response.preferredOu)
          commit('gridPreferred', response.gridPreferred)
        }
      )
    }
  }
}
